import { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
  const nav = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toogleNav = () => {
    nav.current.classList.toggle('show');
  };

  const removeNav = () => {
    nav.current.classList.remove('show');
    scrollToTop();
  };

  return (
    <header>
      <div className="container">
        <Link to="/">
          <img
            className="logo"
            src="/assets/images/logo-pengprov.png"
            alt="logo"
          />
        </Link>
        <nav className="desktop">
          <ul>
            <li onClick={removeNav}>
              <NavLink to="/">Home</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/about">Tentang Budokai</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/activity">Kegiatan</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/news">Informasi</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/dojo">Dojo Budokai</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/member">Anggota Budokai</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/merchandise">Merchandise</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/contact">Kontak Budokai</NavLink>
            </li>
            {/* <li onClick={removeNav}>
              <NavLink to="/cms">CMS</NavLink>
            </li> */}
          </ul>
        </nav>
        <nav className="mobile">
          <ul ref={nav}>
            <li onClick={removeNav}>
              <NavLink to="/">Home</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/about">Tentang Budokai</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/activity">Kegiatan</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/news">Informasi</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/dojo">Dojo Budokai</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/member">Anggota Budokai</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/merchandise">Merchandise</NavLink>
            </li>
            <li onClick={removeNav}>
              <NavLink to="/contact">Kontak Budokai</NavLink>
            </li>
          </ul>
          <span onClick={toogleNav} className="toggle-nav">
            <img src="/assets/images/icon-bar.png" alt="icon" />
          </span>
        </nav>
      </div>
    </header>
  );
}
