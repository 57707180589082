import { useEffect, useState } from 'react';
import './InputImage.scss';

export default function InputImage(props) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (props.imgSrc === '') setImageSrc(null);
  }, [props]);

  const onImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setImageSrc([reader.result]);
    };
    props.changeInputImage(event);
  };

  return (
    <div
      className={
        props.eclipse ? 'input-image-wrapper eclipse' : 'input-image-wrapper'
      }
    >
      {imageSrc ? (
        <img src={imageSrc} alt="" />
      ) : props.imgSrc ? (
        <img src={props.imgSrc} alt="" />
      ) : (
        <p>Upload Image</p>
      )}
      <input
        type="file"
        onChange={onImageChange}
        className="filetype"
        name={props.name}
      />
    </div>
  );
}
