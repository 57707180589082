import { useEffect, useState } from 'react';
import { days, formatedDate, month, months, year } from '../helper';
import Banner from './components/Banner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { fetcherYT, getEvents } from '../services';
import useSWR from 'swr';
import { fetcher } from '../services';

const styleList = [
  {
    id: 1,
    title: 'Shotokan',
    description: `松濤館流 / Shōtōkan-ryū adalah sebuah aliran karate yang
    dikembangkan oleh Gichin Funakoshi (1868 - 1957) dan anaknya
    Gigo (Yoshitaka) Funakoshi (1906 - 1945). Gichin merupakan
    salah satu master karate (selain Kenwa Mabuni dan Choki
    Motobu) yang memperkenalkan karate ke pulau utama Jepang pada
    tahun 1910-an dan 1920. Berpegang pada prinsip Ichigeki
    Hissatsu, yaitu satu gerakan dapat membunuh lawan.`,
    image: '/assets/images/shotokan.png',
  },
  {
    id: 2,
    title: 'Wado-Ryu',
    description: `和道流 / Wado-ryu adalah sebuah aliran karate yang
    dikembangkan oleh Hironori Otsuka pada tahun 1934. Aliran ini
    adalah aliran yang unik karena berakar pada seni beladiri
    Shindo Yoshin-ryu Jujutsu, sebuah aliran beladiri Jepang yang
    memiliki teknik kuncian persendian dan lemparan. Di dalam
    pertarungan, ahli Wado-Ryu menggunakan prinsip Jujutsu yaitu
    lebih banyak menggunakan tangkisan yang bersifat mengalir
    (bukan tangkisan keras) dan kadang menggunakan teknik seperti
    bantingan dan sapuan kaki untuk menjatuhkan lawan.`,
    image: '/assets/images/wado-ryu.png',
  },
  {
    id: 3,
    title: 'Shito-Ryu',
    description: `糸東流 / Shitō-ryū adalah sebuah aliran karate yang diciptakan
    oleh Kenwa Mabuni. Shito-Ryu lebih menekankan pada KATA,
    terbukti banyaknya KATA yang diajarkan. Terdapat 40 KATA,
    jumlah ini lebih besar jika dibandingkan dengan aliran karate
    lainnya, bahkan di Jepang, KATA Shito-Ryu yang tercatat
    terdapat 111 KATA beserta bunkainya. Kelebihan praktisi Karate
    dari aliran Shito-Ryu adalah mereka bisa menyesuaikan diri
    dengan kondisi, bisa bertanding secara frontal seperti
    Shotokan atau bertanding jarak dekat seperti Goju-Ryu.i
    Hissatsu, yaitu satu gerakan dapat membunuh lawan.`,
    image: '/assets/images/shito-ryu.png',
  },
  {
    id: 4,
    title: 'Goju-Ryu',
    description: `剛柔流 / Goju-Ryu adalah salah satu aliran utama Karate
    tradisional Okinawa yang diciptakan oleh Chojun Miyagi. Aliran
    karate ini memadukan teknik “keras dan lembut”. Kedua prinsip
    tersebut berasal dari buku seni beladiri yang cukup populer di
    kalangan praktisi beladiri Okinawa yaitu Bubishi. Go, artinya
    keras, mengacu pada teknik tangan tertutup atau serangan
    lurus. Ju, yang artinya lembut, mengacu pada teknik tangan
    terbuka dan gerakan memutar. Goju-Ryu lebih menekankan kepada
    pernafasan, aliran ini pada umumnya mengajarkan para karateka
    untuk bisa bernafas dengan benar di semua kata khususnya
    Sanchin dan Tensho`,
    image: '/assets/images/gojukai.png',
  },
];

function Welcome() {
  const { data } = useSWR('sambutan', fetcher);
  return (
    <section className="welcome">
      <h2>Sambutan Ketua Umum</h2>
      <div>
        <img
          style={{ borderRadius: '50%' }}
          src={data && data.result[0].url_img && data.result[0].url_img}
          // src="/assets/images/leader.png"
          alt="leader"
        />
        <div>
          <div
            className="mb-2"
            style={{ fontStyle: 'italic' }}
            dangerouslySetInnerHTML={{ __html: data && data.result[0].des }}
          ></div>
          {/* <p>
            <i>{data && data.result[0].des}</i>
          </p> */}
          <p>{data && data.result[0].tittle}</p>
          {/* <a href="/" className="btn btn-danger">
            Lihat Video Sambutan
          </a> */}
        </div>
      </div>
    </section>
  );
}

function Event() {
  const [dates, setDates] = useState([]);
  const [firstDate] = useState(new Date(year, month, 1));
  const [lastDate] = useState(new Date(new Date(year, month + 1, 1) - 1));
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents().then((response) => {
      let ds = [];
      for (let i = firstDate; i <= lastDate; i.setDate(i.getDate() + 1)) {
        ds.push({
          day: new Date(i).getDay(),
          month: new Date(i).getMonth(),
          dateNum: new Date(i).getDate(),
          event: {
            ...response.data.result.find((e) => {
              let year = new Date(e.date).getFullYear();
              let month = new Date(e.date).getMonth();
              let day = new Date(e.date).getDate();
              return new Date(`${year}-${month + 1}-${day}`).getTime() === new Date(i).getTime();
            }),
          },
        });
      }

      setDates((dates) => [...dates, ...ds]);
      setEvents((dates) => [...dates, ...ds.filter((d) => Object.keys(d.event).length)]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="event">
      <h2>Kalender Event</h2>
      <div>
        <div className="event-calendar">
          <div className="head">
            <h4>
              {months[month]} {year}
            </h4>
          </div>
          <div className="body">
            <ul className="days">
              {days.map((d) => (
                <li key={d} className="day">
                  <span>{d}</span>
                </li>
              ))}
            </ul>
            <ul className="dates">
              {[...Array(dates[0]?.day)].map((_, i) => (
                <li key={i} className="date">
                  <span></span>
                </li>
              ))}
              {dates.map((d, i) => (
                <li key={i} className="date">
                  <span className={Object.keys(d?.event).length ? 'active' : ''}>{i + 1}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <h3>Detail Kegiatan</h3>
          <ul>
            {events.length ? (
              events.map((e, i) => (
                <li key={e.dateNum}>
                  <span className="event-date">
                    {e.dateNum} {months[e.month]}
                  </span>
                  <a target="_blank" rel="noopener noreferrer" href={e.event.link} className="event-name">
                    {e.event.title}
                  </a>
                </li>
              ))
            ) : (
              <li>Tidak ada event</li>
            )}
          </ul>
          <Link to="/activity" className="btn btn-danger">
            Lihat Keseluruhan Event
          </Link>
        </div>
      </div>
    </section>
  );
}

function Karate() {
  const [activeTabs, setActiveTabs] = useState(1);
  const [style, setStyle] = useState(styleList[0].id);

  return (
    <section className="karate">
      <ul className="head">
        <li>
          <span onClick={() => setActiveTabs(1)} className={activeTabs === 1 ? 'active' : ''}>
            Sumpah Karate
          </span>
        </li>
        <li>
          <span onClick={() => setActiveTabs(2)} className={activeTabs === 2 ? 'active' : ''}>
            Filosofi Karate
          </span>
        </li>
        <li>
          <span onClick={() => setActiveTabs(3)} className={activeTabs === 3 ? 'active' : ''}>
            Aliran Karate
          </span>
        </li>
      </ul>
      <div className="container">
        {activeTabs === 1 && (
          <div className="oath">
            <img src="/assets/images/karateka.png" alt="karateka" />
            <Swiper
              autoHeight={true}
              spaceBetween={20}
              navigation={true}
              pagination={true}
              modules={[Navigation, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="swiper-item">
                  <h3>SAPTA UBAYA BUDOKAI</h3>
                  <ol>
                    <li>
                      Saya berjanji untuk menjauhkan diri dari perkelahian dan hanya akan menggunakan “ilmu karate” ini
                      untuk membela diri saya atau orang lain yang mengancam jiwanya atau keselamatannya, apabila sudah
                      tidak ada jalan lain.
                    </li>
                    <li>
                      Saya Berjanji tidak melakukan perbuatan yang tercela seperti:
                      <ul>
                        <li>Membuat gara - gara sehingga mengakibatkan perkelahian</li>
                        <li>Menyombongkan atau menonjolkan diri kepada orang lain tentang ilmu Karate saya</li>
                        <li>
                          Menjadi pelindung seseorang apabila saya tahu bahwa orang itu berada di pihak yang salah
                        </li>
                      </ul>
                    </li>
                    <li>
                      Saya berjanji untuk tunduk dan patuh terhadap semua ajaram - ajaran, larangan - larangan serta
                      bimbingan perguruan.
                    </li>
                    <li>
                      Saya berjanji untuk tolong menolong, seia - sekata dengan sesama saudara seperguruan dengan dasar
                      saling hormat - menghormati, harga - menghargai serta menjunjung tinggi sopan santun kewerdaan.
                    </li>
                    <li>
                      Saya berjanji untuk senantiasa berlaku dan bersikap sportif dan ksatria dalam setiap latihan dan
                      pertandingan, serta akan mempertinggi mutu ilmu “Karate” saya.
                    </li>
                    <li>
                      Saya berjanji untuk menjaga nama baik sebagai pemilik ilmu “Karate” khususnya, serta Perguruan
                      BUDO KYOKAI KARATE INDONESIA pada umumnya dalam tingkah laku saya.
                    </li>
                    <li>
                      Saya berjanji untuk menjaga nama baik sebagai pemilik ilmu “Karate” khususnya, serta Perguruan
                      BUDO KYOKAI KARATE INDONESIA pada umumnya dalam tingkah laku saya.
                    </li>
                    <li>Saya berjanji untuk mematuhi peraturan organisasi dan perguruan.</li>
                  </ol>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-item">
                  <h3>TRI PRASETYA</h3>
                  <ol>
                    <li>Sanggup memelihara kepribadian, budi pekerti dan sopan santun</li>
                    <li>Sanggup menguasai diri serta patuh kepada kejujuran</li>
                    <li>Sanggup mempertinggi prestasi</li>
                  </ol>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        )}
        {activeTabs === 2 && (
          <div className="philosophy">
            <ul>
              <li>
                Memegang teguh nilai-nilai <b>BUSHIDO</b>
              </li>
              <li>Berjiwa ksatria</li>
              <li>Setia kawan, dan tolong menolong</li>
              <li>Menjaga nama baik dan taat pada peraturan perguruan</li>
              <li>Saling menghormati dan menjunjung tinggi kesopanan</li>
            </ul>
          </div>
        )}
        {activeTabs === 3 && (
          <div className="style">
            <div className="symbols">
              {styleList.map((s) => (
                <div onClick={() => setStyle(s.id)} key={s.id} className={style === s.id ? 'symbol active' : 'symbol'}>
                  <img src={s.image} alt={s.title} />
                </div>
              ))}
            </div>
            <div className="description">
              {styleList.map(
                (s) =>
                  style === s.id && (
                    <div key={s.id} className="content">
                      <h3>{s.title}</h3>
                      <p>{s.description}</p>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

function News() {
  const { data } = useSWR('beritas?limit=3&order_by=DESC', fetcher);
  return (
    <section className="news">
      <h2>Informasi</h2>
      <div className="container news-thumbnails mt-5">
        {data &&
          data.result.map((d) => (
            <Link
              key={d.id}
              to={'/news/' + d.slugs}
              className="news-thumbnail"
              style={{ backgroundImage: `url(${d.url_image1})` }}
            >
              <div>
                <p>{formatedDate(d.updatedAt, true)}</p>
                <h6>{d.tittle}</h6>
              </div>
            </Link>
          ))}
      </div>
      <div className="link">
        <Link to="/news" className="btn btn-danger">
          Lihat Keseluruhan Informasi
        </Link>
      </div>
    </section>
  );
}

function Video() {
  const [data, setdata] = useState();

  const getVideos = () => {
    fetcherYT(
      'https://www.googleapis.com/youtube/v3/search?key=AIzaSyDUN980YXQVVGZClAvaxb7XUqU4RD9E4NE&channelId=UCZDfAwmM1VgZzAZsneZ4IbA&part=snippet,id'
    ).then((res) => {
      localStorage.setItem('ytvids', JSON.stringify(res));
      setdata(res);
    });
  };

  useEffect(() => {
    let timeNow = new Date().getTime();
    let timeSaved = Number(localStorage.getItem('ytreftime') || 0) || timeNow;

    if (timeNow > timeSaved) {
      localStorage.setItem('ytreftime', timeNow + 86400000);
      getVideos();
    } else {
      localStorage.setItem('ytreftime', timeSaved);
      if (localStorage.getItem('ytvids')) {
        setdata(JSON.parse(localStorage.getItem('ytvids')));
      } else {
        getVideos();
      }
    }
  }, []);

  return (
    <section className="videos">
      <h2>Videos</h2>
      <div className="container video-thumbnails mt-5">
        <ul>
          {data &&
            data.items.map((d) => (
              <li key={d.id.videoId}>
                <iframe
                  width="100%"
                  height="180"
                  src={'https://www.youtube.com/embed/' + d.id.videoId}
                  title={d.snippet.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <p>
                  <a href={'https://youtu.be/' + d.id.videoId} target="_blank" rel="noreferrer">
                    {d.snippet.title}
                  </a>
                </p>
              </li>
            ))}
        </ul>
      </div>
      <div className="link">
        <a href="https://youtube.com/@BUDOKAIJAKARTA" className="btn btn-danger">
          Lihat Keseluruhan Video
        </a>
      </div>
    </section>
  );
}

export default function Home() {
  const { data } = useSWR('banners?menu=home', fetcher);
  return (
    <div className="home">
      {data && <Banner images={data.result} />}
      <Welcome />
      <Event />
      <Karate />
      <News />
      <Video />
    </div>
  );
}
