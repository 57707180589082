import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper';

export default function Gallery({ isShow, onClose, title, images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="gallery">
      <div
        className={isShow ? 'gallery modal fade show' : 'gallery modal fade'}
        id="galleryModal"
        tabIndex="-1"
        aria-labelledby="galleryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {title}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onClose()}
              ></button>
            </div>
            <div className="modal-body">
              <Swiper
                style={{
                  '--swiper-navigation-color': '#bbb',
                  '--swiper-pagination-color': '#bbb',
                }}
                autoHeight={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {images
                  .filter((img) => img.source !== '')
                  .map((img) => (
                    <SwiperSlide key={img.id}>
                      <img src={img.source} alt="foto" />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <br />
              <div className="thumbnail">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {images
                    .filter((img) => img.source !== '')
                    .map((img) => (
                      <SwiperSlide key={img.id}>
                        <img src={img.source} alt="foto" />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => onClose()}
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
