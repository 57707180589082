import { useState } from 'react';
import { fetcher, postSuggestion } from '../services';
import Banner from './components/Banner';
import useSWR from 'swr';
import Info from '../components/Info';

export default function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });

  const { data } = useSWR('organisations', fetcher);
  const { data: dataBanner } = useSWR('banners?menu=kontak', fetcher);

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      const response = await postSuggestion(formData);
      setInfo({ isShow: true, type: 'success', text: response.data.msg });
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact">
      {dataBanner && <Banner images={dataBanner.result} />}

      <section>
        <div className="container">
          <div className="branches">
            {data &&
              data.result.map((d) => (
                <div key={d.id} className="branch">
                  <h3>{d.name}</h3>
                  <h6 className="subtitle">Sekretariat</h6>
                  <p className="address">{d.addr}</p>
                  <ul>
                    {d.ig && (
                      <li>
                        <img src="/assets/images/icon-ig-red.png" alt="icon" />
                        <span>{d.ig}</span>
                      </li>
                    )}
                    {d.email1 && (
                      <li>
                        <img src="/assets/images/icon-mail-red.png" alt="icon" />
                        <span>{d.email1}</span>
                      </li>
                    )}
                    {d.email2 && (
                      <li>
                        <img src="/assets/images/icon-mail-red.png" alt="icon" />
                        <span>{d.email2}</span>
                      </li>
                    )}
                  </ul>
                  <a href={d.gmap} target="_blank" rel="noreferrer">
                    Lihat Melalui Map &rarr;
                  </a>
                </div>
              ))}
          </div>
          <div className="contact-form">
            <h2>ANDA MEMILIKI PERTANYAAN ?</h2>
            <p>Silahkan isi kolom di bawah ini, kami akan menghubungi anda kembali</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nama"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="No. Telepon"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
              </div>
              <div className="form-group">
                <textarea
                  cols="30"
                  rows="5"
                  className="form-control form-control-lg"
                  placeholder="Isi Pesan"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                ></textarea>
              </div>

              {info.isShow && (
                <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />
              )}
              <div className="form-group">
                <button className="btn btn-danger" type="submit" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
