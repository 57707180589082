import { useState } from 'react';
import Table from '../../components/Table';
import useSWR from 'swr';
import { deleteBanner, fetcherAuth } from '../../../services';
import Info from '../../../components/Info';
import AddEditBanner from './AddEditBanner';
import { excerpt, menusBanner } from '../../../helper';
import Pagination from '../../components/Pagnation';

const columns = [
  'No',
  'Menu',
  'Title',
  'Description',
  'Images',
  'Link',
  'Action',
];

function Row({
  result,
  isDeleting,
  onIsShow,
  onIsEdit,
  onEditData,
  onDeleteRow,
  isCode = false,
}) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td style={{ textTransform: 'capitalize' }}>{d.menu}</td>
        <td>{d.tittle}</td>
        <td>{excerpt(d.des ? d.des : '', 40)}</td>
        <td>
          <img src={d.url_img} style={{ width: '80px' }} alt="" />
        </td>
        <td>
          <a href={d.link} target="_blank" rel="noreferrer">
            {d.link}
          </a>
        </td>
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function Banner() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [menuFilter, setMenuFilter] = useState('');
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('banners', fetcherAuth);

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataWC =
    data &&
    data.result
      .filter((d) => (menuFilter !== '' ? d.menu === menuFilter : true))
      .map((d, i) => {
        return { ...d, no: i + 1 };
      });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteBanner(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Banner</h2>
      <hr />

      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Banner"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <select
            className="form-select"
            value={menuFilter}
            onChange={(e) => setMenuFilter(e.target.value)}
          >
            <option value=""> -- Filter Banner --</option>
            {menusBanner.map((m) => (
              <option key={m} style={{ textTransform: 'capitalize' }} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
      </div>
      {info.isShow && (
        <Info
          type={info.type}
          text={info.text}
          onClose={() => setInfo({ ...info, isShow: false })}
        />
      )}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <AddEditBanner
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
