import { useState } from 'react';
import Banner from './components/Banner';
import Pagination from './components/Pagination';
import Gallery from './components/Gallery';
import { fetcher } from '../services';
import useSWR from 'swr';
import { scrollToTop } from '../helper';

export default function Dojo() {
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [selectedDojo, setSelectedDojo] = useState(0);
  const [pengcab, setPengcab] = useState('');
  const [searchName, setSearchName] = useState('');
  const [url, setUrl] = useState(`dojos?limit=${limit}&page=${page}`);
  const [isShow, setIsShow] = useState(false);
  const { data, isLoading } = useSWR(url, fetcher);
  const { data: dataPengcab } = useSWR(`organisations`, fetcher);
  const { data: dataBanner } = useSWR('banners?menu=dojo', fetcher);

  const filterData = (e) => {
    e.preventDefault();
    setPage(0);
    setUrl(`dojos?limit=${limit}&page=0&search_name=${searchName}&search_pengcab=${pengcab}`);
  };

  const handleChangePage = (e) => {
    setPage(e);
    setUrl(`dojos?limit=${limit}&page=${e}&search_name=${searchName}&search_pengcab=${pengcab}`);
    scrollToTop();
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setPage(0);
    setUrl(`dojos?limit=${e}&page=${0}&search_name=${searchName}&search_pengcab=${pengcab}`);
    scrollToTop();
  };

  return (
    <div className="dojo">
      {dataBanner && <Banner images={dataBanner.result} />}
      <section>
        <h2>Daftar Dojo Budokai</h2>
        <div className="container">
          <form className="row" onSubmit={filterData}>
            <div className="col-md-3 mb-1">
              <select className="form-select" onChange={(e) => setPengcab(e.target.value)} value={pengcab}>
                <option value="">All</option>
                {dataPengcab &&
                  dataPengcab.result.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 mb-1">
              <input
                type="text"
                className="form-control search"
                placeholder="Cari Nama Dojo"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <button type="submit" className="btn btn-danger btn-sm mb-3">
                Cari
              </button>
            </div>
          </form>
          <div className="table-wrapper">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Pengcab</th>
                  <th>Nama Dojo</th>
                  <th>Alamat</th>
                  <th></th>
                  <th>PIC</th>
                  <th>Kontak</th>
                  <th>Jadwal Latihan</th>
                  <th>Sosmed</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={8}>Mengambil data...</td>
                  </tr>
                )}
                {data && data.result.length ? (
                  data.result.map((d, i) => (
                    <tr key={d.id}>
                      <td>
                        <label>Pengcab</label> <div>{d.organisation.name}</div>
                      </td>
                      <td>
                        <label>Nama Dojo</label>
                        <div>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedDojo(i);
                              setIsShow(true);
                            }}
                          >
                            {d.name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <label>Alamat</label>
                        <div>{d.addr}</div>
                      </td>
                      <td>
                        <label>&nbsp;</label>
                        <div>
                          <a href={d.gmap} className="btn btn-danger fit" target="_blank" rel="noreferrer">
                            <img src="assets/images/icon-pinpoint.png" alt="icon" />
                          </a>
                        </div>
                      </td>
                      <td>
                        <label>PIC</label>
                        <div>
                          <ul>
                            {d.dojopics.map((d) => (
                              <li key={d.id}>
                                <span
                                  style={{ cursor: 'pointer' }}
                                  key={d.id}
                                  onClick={() => setMemberId(d.anggotum.id)}
                                >
                                  {d.anggotum.name}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                      <td>
                        <label>Kontak</label>
                        <div>{d.kontak}</div>
                      </td>
                      <td>
                        <label>Jadwal Latihan</label>
                        <div>
                          <p dangerouslySetInnerHTML={{ __html: d.jadwal }}></p>
                        </div>
                      </td>
                      <td>
                        <label>Sosmed</label>
                        <div>
                          {d.sosmed === true && (
                            <a href={d.sosmed} target="_blank" rel="noreferrer">
                              <img src="assets/images/icon-ig-dojo.png" alt="icon" />
                              {d.sosmed}
                            </a>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>Data Kosong</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {data && (
            <Pagination
              data={data}
              onChangeLimit={(e) => handleChangeLimit(e)}
              onChangePage={(e) => handleChangePage(e)}
              limit={limit}
              page={page}
            />
          )}
        </div>
      </section>
      {data && data.result.length && (
        <Gallery
          isShow={isShow}
          onClose={() => setIsShow(false)}
          title={data.result.name}
          images={data.result[selectedDojo].url_image.split(',').map((img, i) => ({ id: i + 1, source: img }))}
        />
      )}
      {memberId !== 0 && (
        <div className="pic" onClick={() => setMemberId(0)}>
          <MemberDetail id={memberId} />
        </div>
      )}
    </div>
  );
}

export function MemberDetail({ id }) {
  // return <div className="pic-card">{id}</div>;
  const { data } = useSWR('anggota/' + id, fetcher);
  return (
    <div className="pic-card">
      {data && (
        <div key={data.result.id} className="item">
          <img
            src={
              data.result.url_foto
                ? data.result.url_foto
                : 'https://apidev.budokaijakarta.org/anggota/photo_6258075209689052837_m.jpg'
            }
            alt="member"
          />
          <div>
            <h5>{data.result.name}</h5>
            <p>
              Sabuk {data.result.sabuk && data.result.sabuk.des} - {data.result.sabuk && data.result.sabuk.grade}
            </p>
            <p>{data.result.dojo.name}</p>
            <p>{data.result.dojo.organisation.name.replace('Pengurus', '')}</p>
          </div>
        </div>
      )}
    </div>
  );
}
