import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services';
import './auth.scss';
import Info from '../components/Info';

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });

  const navigate = useNavigate();

  const authenticating = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      const response = await login(formData);

      localStorage.setItem('userData', JSON.stringify(response.data));
      navigate('/cms');
    } catch (err) {
      console.log(err);
      setInfo({
        isShow: true,
        type: 'danger',
        text: err.response.data.msg || err.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={authenticating}>
        {info.isShow && (
          <Info
            type={info.type}
            text={info.text}
            onClose={() => setInfo({ ...info, isShow: false })}
          />
        )}
        <h1>Budokai CMS</h1>
        <hr />
        <div className="form-group mt-4">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div className="form-group mt-4">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
        </div>
        <div className="form-group mt-4">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            Login
          </button>
        </div>
      </form>
      <p>&copy; Budo Kyokai 2023</p>
    </div>
  );
}
