import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcherAuth, updateVisi } from '../../../services';
import { excerpt } from '../../../helper';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddEditVisi({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    tittle: '',
    des: '',
  });
  const [des, setDes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setFormData(editData ?? { ...editData });
    setDes(editData && editData.des && editData.des);
  }, [editData]);

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('tittle', formData.tittle);
    formDataObj.append('des', des);
    try {
      const response = await updateVisi(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      setIsShow(false);
    }
  };

  const isFormEmpty = () => {
    if (formData.tittle && des) {
      return false;
    }

    return true;
  };

  const onChangeDesc = (value) => {
    setDes(value);
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => setIsShow(false)}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Visi</h1>
            <button type="button" className="btn-close" onClick={() => setIsShow(false)}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="tittle" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  id="tittle"
                  className="form-control"
                  value={formData.tittle ? formData.tittle : ''}
                  onChange={(e) => setFormData({ ...formData, tittle: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="des" className="form-label">
                  Description
                </label>
                <ReactQuill theme="snow" value={des ? des : ''} onChange={onChangeDesc} />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => setIsShow(false)}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = ['No', 'Title', 'Description'];

export default function Visi() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('visi', fetcherAuth);

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  function dataRow() {
    return (
      data &&
      data.result.map((d, i) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{d.tittle}</td>
          <td>{excerpt(d.des, 120)}</td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                setIsShow(true);
                setIsEdit(true);
                setEditData(d);
              }}
            >
              Edit
            </button>
          </td>
        </tr>
      ))
    );
  }

  return (
    <div>
      <h2>Visi</h2>
      <hr />
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table columns={columns} children={dataRow()} />
      {/* <Pagination /> */}
      <AddEditVisi
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
