import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../services';

export default function Footer() {
  const { data } = useSWR('contacts', fetcher);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer>
      <div className="container">
        {data && (
          <>
            <div className="left">
              <img src="/assets/images/logo-pengprov-white.png" alt="logo" />
              <div>
                <h3>BUDO KYOKAI KARATE INDONESIA</h3>
                <p>{data.result[0].addr}</p>
                {data.result[0].instagram && (
                  <p className="text-icon">
                    <img src="/assets/images/icon-ig-white.png" alt="ig" />
                    <span>{data.result[0].instagram}</span>
                  </p>
                )}
                <p className="text-icon">
                  <img src="/assets/images/icon-mail.png" alt="mail" />
                  <span>{data.result[0].email}</span>
                </p>
                {data.result[0].email2 && (
                  <p className="text-icon">
                    <img src="/assets/images/icon-mail.png" alt="mail" />
                    <span>{data.result[0].email2}</span>
                  </p>
                )}
              </div>
            </div>
            <div className="mid">
              <nav>
                <ul>
                  <li>
                    <Link onClick={scrollToTop} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/about">
                      Tentang Budokai
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/activity">
                      Kegiatan
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/news">
                      Informasi
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/dojo">
                      Dojo Budokai
                    </Link>
                  </li>
                </ul>
                <ul className="justify-content-center mt-2">
                  <li>
                    <Link onClick={scrollToTop} to="/member">
                      Anggota Budokai
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/merchandise">
                      Merchandise
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/contact">
                      Kontak Budokai
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="right">
              {data.result[0].instagram && (
                <a href={`https://instagram.com/${data.result[0].instagram}`} target="_blank" rel="noreferrer">
                  <img src="/assets/images/icon-ig.png" alt="ig" />
                </a>
              )}
              {data.result[0].facebook && (
                <a href={`https://facebook.com/${data.result[0].facebook}`} target="_blank" rel="noreferrer">
                  <img src="/assets/images/icon-fb.png" alt="fb" />
                </a>
              )}

              {/* <a href="youtube.com" target="_blank" rel="noreferrer">
                <img src="/assets/images/icon-yt.png" alt="yt" />
              </a> */}
            </div>
          </>
        )}
      </div>
      <p>Copyright © 2023 by BUDO KYOKAI KARATE INDONESIA</p>
    </footer>
  );
}
