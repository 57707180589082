import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { addKatMerch, deleteKatMerch, fetcherAuth, updateKatMerch } from '../../../services';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';

function AddEditMerchCategory({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    des: '',
    isactive: '',
  });
  const [des, setDes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
    setDes(editData && editData.des && editData.des);
  }, [editData]);

  const clearForm = () => {
    setFormData({
      des: '',
      isactive: '',
    });
    setDes('');
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('des', des);
    formData.isactive && formDataObj.append('isactive', formData.isactive);
    try {
      const response = isEdit ? await updateKatMerch(formDataObj, editData.id) : await addKatMerch(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;
    let obj = { des: des };

    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Kategori Merch</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="des" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  id="des"
                  className="form-control"
                  value={des ? des : ''}
                  onChange={(e) => setDes(e.target.value)}
                />
              </div>
              {isEdit && (
                <div className="form-group mb-3">
                  <label htmlFor="des" className="form-label">
                    Is Active
                  </label>
                  <select
                    className="form-select"
                    value={formData.isactive ? formData.isactive : ''}
                    onChange={(e) => setFormData({ ...formData, isactive: e.target.value })}
                  >
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = ['No', 'Description', 'Is Active', 'Action'];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isCode = false }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td>{d.des}</td>
        <td>{d.isactive === 'Y' ? 'Yes' : 'No'}</td>
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function MerchCategory() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchData, setSearchData] = useState('');
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('kategorimrcs', fetcherAuth);

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataWC =
    data &&
    data.result
      .filter((d) => {
        let pattern = new RegExp(searchData, 'ig');
        return pattern.test(d.des);
      })
      .map((d, i) => {
        return { ...d, no: i + 1 };
      });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteKatMerch(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Kategori Merch</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Kategori Merch"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Description"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <AddEditMerchCategory
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
