import { useEffect, useState } from 'react';
import { addBanner, updateBanner } from '../../../services';
import InputImage from '../../components/InputImage';
import { menusBanner } from '../../../helper';

const menus = menusBanner;

export default function AddEditBanner({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    menu: '',
    tittle: '',
    des: '',
    url_img: '',
    link: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
  }, [editData]);

  const clearForm = () => {
    setFormData({
      menu: '',
      tittle: '',
      des: '',
      url_img: '',
      link: '',
    });
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('menu', formData.menu);
    formDataObj.append('tittle', formData.tittle);
    formDataObj.append('des', formData.des);
    formDataObj.append('link', formData.link);
    typeof formData.url_img !== 'string' && formDataObj.append('img', formData.url_img);
    try {
      const response = isEdit ? await updateBanner(formDataObj, editData.id) : await addBanner(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    // let result = false;
    // for (const [_, value] of Object.entries(formData)) {
    //   if (!value) {
    //     result = true;
    //     break;
    //   }
    // }

    // return result;
    return !formData.menu;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Banner</h1>
            <button type="button" className="btn-close" onClick={clearForm}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="menu" className="form-label">
                  Menu
                </label>
                <select
                  className="form-select"
                  id="menu"
                  value={formData.menu ? formData.menu : ''}
                  onChange={(e) => setFormData({ ...formData, menu: e.target.value })}
                >
                  <option value="">Pilih Menu</option>
                  {menus.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="text"
                  id="menu"
                  className="form-control"
                  value={formData.menu ? formData.menu : ''}
                  onChange={(e) =>
                    setFormData({ ...formData, menu: e.target.value })
                  }
                /> */}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  value={formData.tittle ? formData.tittle : ''}
                  onChange={(e) => setFormData({ ...formData, tittle: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="des" className="form-label">
                  Description
                </label>
                <textarea
                  id="des"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={formData.des ? formData.des : ''}
                  onChange={(e) => setFormData({ ...formData, des: e.target.value })}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="link" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  id="link"
                  className="form-control"
                  value={formData.link ? formData.link : ''}
                  onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image</label>
                <InputImage
                  imgSrc={formData.url_img ? formData.url_img : ''}
                  name="url_img"
                  changeInputImage={(e) => setFormData({ ...formData, url_img: e.target.files[0] })}
                />
                {/* <input
                  className="form-control"
                  type="file"
                  id="img"
                  onChange={(e) =>
                    setFormData({ ...formData, img: e.target.files[0] })
                  }
                ></input> */}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={clearForm}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
