import { useState } from 'react';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';
import useSWR from 'swr';
import { fetcherAuth } from '../../../services';
import Info from '../../../components/Info';
import AddEditContact from './AddEditContact';
import { excerpt } from '../../../helper';

const columns = [
  'No',
  'Address',
  'Phone',
  'Email',
  'Email 2',
  'Instagram',
  'Facebook',
];

export default function ContactCMS() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('contacts', fetcherAuth);

  const handleSubmit = (response) => {
    if (response.data.msg) {
      setInfo({ isShow: true, type: 'success', text: response.data.msg });
      mutate();
    } else {
      setInfo({ isShow: true, type: 'danger', text: response.message });
    }
  };

  function dataRow() {
    return (
      data &&
      data.result.map((d, i) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{d.addr}</td>
          <td>{d.phone}</td>
          <td>{excerpt(d.email, 10)}</td>
          <td>{excerpt(d.email2, 10)}</td>
          <td>
            <a
              href={'https://instagram.com/' + d.instagram}
              target="_blank"
              rel="noreferrer"
            >
              {d.instagram}
            </a>
          </td>
          <td>
            <a
              href={'https://facebook.com/' + d.facebook}
              target="_blank"
              rel="noreferrer"
            >
              {d.facebook}
            </a>
          </td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                setIsShow(true);
                setIsEdit(true);
                setEditData(d);
              }}
            >
              Edit
            </button>
          </td>
        </tr>
      ))
    );
  }

  return (
    <div>
      <h2>Contact</h2>
      <hr />
      {info.isShow && (
        <Info
          type={info.type}
          text={info.text}
          onClose={() => setInfo({ ...info, isShow: false })}
        />
      )}
      <Table columns={columns} children={dataRow()} />
      {/* <Pagination /> */}
      <AddEditContact
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
