import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { addEvent, deleteEvent, fetcherAuth, updateEvent } from '../services';
import Info from '../components/Info';
import Table from './components/Table';
import Pagination from './components/Pagnation';

const columns = ['No', 'Code', 'Title', 'Date', 'Link', 'Action'];

function AddEditEvent({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    code: '',
    title: '',
    date: '',
    link: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
  }, [editData]);

  const clearForm = () => {
    setFormData({
      code: '',
      title: '',
      date: '',
      link: '',
    });
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('code', formData.code);
    formDataObj.append('title', formData.title);
    formDataObj.append('date', formData.date);
    formDataObj.append('link', formData.link);
    try {
      const response = isEdit ? await updateEvent(formDataObj, editData.code) : await addEvent(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;
    let obj = { ...formData, link: 'x' };

    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => clearForm()}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Kegiatan</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="code" className="form-label">
                  Code
                </label>
                <input
                  type="text"
                  id="code"
                  className="form-control"
                  value={formData.code ? formData.code : ''}
                  onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  value={formData.title ? formData.title : ''}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  value={formData.date ? formData.date : ''}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="link" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  id="link"
                  className="form-control"
                  value={formData.link ? formData.link : ''}
                  onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isCode = false }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td>{d.code}</td>
        <td>{d.title}</td>
        <td>
          {new Date(d.date).toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </td>
        <td>
          <a href={d.link} target="_blank" rel="noreferrer">
            {d.link}
          </a>
        </td>
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function Events() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('events', fetcherAuth);

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataWC =
    data &&
    data.result
      .filter((d) => {
        if (fromDate && toDate) {
          return (
            new Date(d.date).getTime() >= new Date(fromDate).getTime() &&
            new Date(d.date).getTime() <= new Date(toDate).getTime()
          );
        }
        return d;
      })
      .map((d, i) => {
        return { ...d, no: i + 1 };
      });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteEvent(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Kegiatan</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Kegiatan"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-center gap-2">
            <label>From</label>
            <input
              type="date"
              className="form-control"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center gap-2">
            <label>To</label>
            <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </div>
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
            isCode={true}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <AddEditEvent
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
