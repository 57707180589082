export const navs = [
  {
    id: 0,
    label: 'Dashboard',
    path: '/cms',
    childs: [],
  },
  {
    id: 1,
    label: 'Banner',
    path: '/cms/banner',
    childs: [],
  },

  {
    id: 3,
    label: 'Sambutan',
    path: '/cms/greeting',
    childs: [],
  },
  {
    id: 6,
    label: 'Tentang Budokai',
    path: '/cms/about',
    childs: [
      {
        id: 4,
        label: 'Makna Lambang',
        path: '/cms/about',
      },
      {
        id: 2,
        label: 'Visi',
        path: '/cms/about/visi',
      },
      {
        id: 3,
        label: 'Misi',
        path: '/cms/about/misi',
      },
      {
        id: 5,
        label: 'Mars',
        path: '/cms/about/mars',
      },
      {
        id: 1,
        label: 'Struktur Organisasi',
        path: '/cms/about/org-structure',
      },
    ],
  },
  {
    id: 4,
    label: 'Kegiatan',
    path: '/cms/events',
    childs: [],
  },
  {
    id: 5,
    label: 'Informasi',
    path: '/cms/news',
    childs: [],
  },
  {
    id: 7,
    label: 'Dojo',
    path: '/cms/dojos',
    childs: [],
  },
  {
    id: 8,
    label: 'Anggota',
    path: '/cms/members',
    childs: [],
  },

  {
    id: 9,
    label: 'Merchandise',
    path: '/cms/merchandise',
    childs: [],
  },
  {
    id: 10,
    label: 'Kontak',
    path: '/cms/contacts',
    childs: [
      {
        id: 1,
        label: 'Saran',
        path: '/cms/contacts',
      },
      {
        id: 2,
        label: 'Organisasi',
        path: '/cms/contacts/org',
      },
    ],
  },
  {
    id: 11,
    label: 'Data',
    path: '/cms/data',
    childs: [
      {
        id: 1,
        label: 'Level Org',
        path: '/cms/data',
      },
      {
        id: 2,
        label: 'Sabuk',
        path: '/cms/data/sabuk',
      },
      {
        id: 3,
        label: 'Jabatan',
        path: '/cms/data/jabatan',
      },
      {
        id: 4,
        label: 'Kategori Merch',
        path: '/cms/data/merch-category',
      },
    ],
  },
  {
    id: 2,
    label: 'Contact Footer',
    path: '/cms/contact-footer',
    childs: [],
  },
];
export const data = [
  {
    id: 1,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 2,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 3,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 4,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 5,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 6,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 7,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 8,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 9,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 10,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 11,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 12,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 13,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 14,
    label: 'xxx',
    desc: 'yyy',
  },
  {
    id: 15,
    label: 'xxx',
    desc: 'yyy',
  },
];
export const columns = ['No', 'UUID', 'Name', 'Email', 'Role'];
export const date = new Date();
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const days = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  "Jum'at",
  'Sabtu',
];
export const month = date.getMonth();
export const year = date.getFullYear();

export const firstDate = new Date(year, month, 1);
export const lastDate = new Date(new Date(year, month + 1, 1) - 1);

export const getDates = () => {
  let dates = [];
  for (let i = firstDate; i <= lastDate; i.setDate(i.getDate() + 1)) {
    dates.push(new Date(i).getDay());
  }
  return dates;
};

export const excerpt = (str, length = 180) => {
  if (str.length <= length) return str;
  return str.substring(0, length) + '...';
};

export const formatedDate = (date, long = false) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  if (long) return new Date(date).toLocaleDateString('id-ID', options);
  return new Date(date).toLocaleDateString('id-ID');
};

export const abjads = [
  { name: 'A' },
  { name: 'B' },
  { name: 'C' },
  { name: 'D' },
  { name: 'E' },
  { name: 'F' },
  { name: 'G' },
  { name: 'H' },
  { name: 'I' },
  { name: 'J' },
  { name: 'K' },
  { name: 'L' },
  { name: 'M' },
  { name: 'N' },
  { name: 'O' },
  { name: 'P' },
  { name: 'Q' },
  { name: 'R' },
  { name: 'S' },
  { name: 'T' },
  { name: 'U' },
  { name: 'V' },
  { name: 'W' },
  { name: 'X' },
  { name: 'Y' },
  { name: 'Z' },
];

export const scrollToTop = () => {
  window.scrollTo({
    top: '400',
    behavior: 'smooth',
  });
};

export const removeTags = (str) => {
  if (str === null || str === '') return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, '');
};

export const menusBanner = [
  'home',
  'merchandise',
  'about',
  'kegiatan',
  'informasi',
  'dojo',
  'kontak',
  'anggota',
];
