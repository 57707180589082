import { useEffect } from 'react';
import Banner from './components/Banner';
import { Link, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../services';
import { useState } from 'react';

export default function News() {
  const location = useLocation();
  let path = location.pathname.split('/');
  const [slug, setSlug] = useState(path[path.length - 1]);
  const { data } = useSWR(`beritas/${slug}`, fetcher);
  const { data: relatedNews } = useSWR(
    'beritas?limit=4&order_by=DESC',
    fetcher
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="news-detail">
      {data && <Banner images={data.result.url_image1} />}
      <section>
        {data && (
          <div className="container">
            <div className="breadcrumb">
              <Link to="/news">Informasi</Link> &nbsp; {' > '} &nbsp;
              <span>{data.result.tittle}</span>
            </div>
            {/* <h2 className="text-left">Mentas di Kejuaraan Asia 2022</h2> */}
            <h2 className="text-left">{data.result.tittle}</h2>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.result.body }}
            ></div>
            <br />
            <div className="related-news">
              <h3>Related News</h3>
              <hr />
              <div className="container news-thumbnails mt-5">
                <ul>
                  {relatedNews &&
                    relatedNews.result.map((r) => (
                      <li key={r.id}>
                        {/* <img src={r.url_image1} alt="" /> */}
                        <div
                          style={{ backgroundImage: `url(${r.url_image2})` }}
                        ></div>
                        <p>
                          <Link
                            onClick={() => {
                              setSlug(r.slugs);
                              scrollToTop();
                            }}
                            to={'/news/' + r.slugs}
                          >
                            {r.tittle}
                          </Link>
                        </p>
                      </li>
                    ))}
                  {/* <li>
                    <img src="/assets/images/news4.png" alt="" />
                    <p>WKF 2022 - JAPAN Male Kata Team Cadet Junior</p>
                  </li>
                  <li>
                    <img src="/assets/images/news4.png" alt="" />
                    <p>
                      Final WKF 2022 Nadlima Layla (INA) Vs Takuta A (JPN) -
                      Female Cadet Kata
                    </p>
                  </li>
                  <li>
                    <img src="/assets/images/news4.png" alt="" />
                    <p>
                      WKF 2022 - Tiara Sandy (INA) Vs Togashi K (JPN) - Junior
                      Female Kumite +59 Kg
                    </p>
                  </li>
                  <li>
                    <img src="/assets/images/news4.png" alt="" />
                    <p>
                      WKF - Hazel Ramadhan - Male Kata IND Junior Konya Turkey
                      2022
                    </p>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
