import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { excerpt } from '../../helper';

export function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/images/org-left.png" alt="prev" />
    </div>
  );
}

export function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/images/org-right.png" alt="next" />
    </div>
  );
}

export default function Banner({
  onlyImage = false,
  slider = true,
  image,
  images,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const bannerContent = (images) => {
    if (images) {
      if (images.length > 1 && typeof images === 'object') {
        return (
          <Slider {...settings}>
            {images.map((img) => (
              <div key={img.id}>
                <div
                  className="banner-item"
                  style={{
                    backgroundImage: `url(${img.url_img})`,
                  }}
                >
                  <div className="container">
                    {img.tittle && <h2>{img.tittle}</h2>}
                    {img.des && <p>{excerpt(img.des, 80)}</p>}
                    {img.link && (
                      <Link className="btn btn-warning mt-4" to={img.link}>
                        Baca Lebih Lengkap
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        );
      } else {
        return (
          <div
            className="banner-item"
            style={{ backgroundImage: `url(${images[0].url_img || images})` }}
          >
            {/* {!onlyImage && (
              <div className="container">
                <h2>Internal Circuit Budokai</h2>
                <p>
                  Perguruan Budo Kyokai Karate Indonesia atau yang biasa disebut
                  budokai sukses mengadakan pertandingan internal pada tanggal 17
                  Desember 2022
                </p>
                <a href="/" className="btn btn-warning mt-4">
                  Baca Lebih Lengkap
                </a>
              </div>
            )} */}
          </div>
        );
      }
    }
  };

  return <div className="banner">{bannerContent(images)}</div>;
}
