import { useState } from 'react';
import { deleteSaran, fetcherAuth } from '../../../services';
import useSWR from 'swr';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';
import { excerpt } from '../../../helper';

const columns = ['No', 'Name', 'Email', 'Phone', 'Message', 'Action'];

function Message({ msg, setMsg }) {
  return (
    <div className={msg ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => setMsg('')}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">Message</h1>
            <button type="button" className="btn-close" onClick={() => setMsg('')}></button>
          </div>
          <div className="modal-body">
            <p>{msg}</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => setMsg('')}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Row({ result, isDeleting, onDeleteRow, isCode = false, showMessage }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td>{d.name}</td>
        <td>{d.email}</td>
        <td>{d.phone}</td>
        <td style={{ cursor: 'pointer' }} onClick={() => showMessage(d.message)}>
          {excerpt(d.message, 40)}
        </td>

        <td>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function Saran() {
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState('');
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('sarans', fetcherAuth);

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataWC =
    data &&
    data.result.map((d, i) => {
      return { ...d, no: i + 1 };
    });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteSaran(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <h2>Saran</h2>
      <hr />
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row result={currentItems} isDeleting={isDeleting} onDeleteRow={deleteRow} showMessage={setMessage} />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <Message msg={message} setMsg={setMessage} />
    </div>
  );
}
