import { useEffect, useState } from 'react';
import { addOrg, deleteOrg, fetcher, fetcherAuth, updateOrg } from '../../../services';
import useSWR from 'swr';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import { excerpt } from '../../../helper';
import Pagination from '../../components/Pagnation';

function AddEditOrg({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    addr: '',
    email1: '',
    email2: '',
    ig: '',
    gmap: '',
    levelorg: { code: '' },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataOrg, setDataOrg] = useState([]);

  useEffect(() => {
    editData && setFormData(editData);
  }, [editData]);

  const getOrg = () => {
    fetcher(`levelorgs`).then((res) => {
      setDataOrg(res.result);
    });
  };

  useEffect(() => {
    getOrg();
  }, []);

  const clearForm = () => {
    setFormData({
      code: '',
      name: '',
      addr: '',
      email1: '',
      email2: '',
      ig: '',
      gmap: '',
      levelorg: { code: '' },
    });
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('code', formData.code);
    formDataObj.append('name', formData.name);
    formDataObj.append('addr', formData.addr);
    formDataObj.append('email1', formData.email1);
    formDataObj.append('email2', formData.email2);
    formDataObj.append('ig', formData.ig);
    formDataObj.append('gmap', formData.gmap);
    formDataObj.append('levelorgid', formData.levelorg.code);
    try {
      const response = isEdit ? await updateOrg(formDataObj, editData.code) : await addOrg(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;
    let obj = { ...formData, email2: 'x', ig: 'x' };

    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Organisasi</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="code" className="form-label">
                  Code
                </label>
                <input
                  type="text"
                  id="code"
                  className="form-control"
                  value={formData.code ? formData.code : ''}
                  onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={formData.name ? formData.name : ''}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="addr" className="form-label">
                  Address
                </label>
                <textarea
                  id="addr"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={formData.addr ? formData.addr : ''}
                  onChange={(e) => setFormData({ ...formData, addr: e.target.value })}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email1" className="form-label">
                  Email 1
                </label>
                <input
                  type="email"
                  id="email1"
                  className="form-control"
                  value={formData.email1 ? formData.email1 : ''}
                  onChange={(e) => setFormData({ ...formData, email1: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email2" className="form-label">
                  Email 2
                </label>
                <input
                  type="email"
                  id="email2"
                  className="form-control"
                  value={formData.email2 ? formData.email2 : ''}
                  onChange={(e) => setFormData({ ...formData, email2: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="ig" className="form-label">
                  Instagram
                </label>
                <input
                  type="text"
                  id="ig"
                  className="form-control"
                  value={formData.ig ? formData.ig : ''}
                  onChange={(e) => setFormData({ ...formData, ig: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="gmap" className="form-label">
                  Map
                </label>
                <input
                  type="text"
                  id="gmap"
                  className="form-control"
                  value={formData.gmap ? formData.gmap : ''}
                  onChange={(e) => setFormData({ ...formData, gmap: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Level Organisasi</label>
                <select
                  className="form-select"
                  value={formData.levelorg.code ? formData.levelorg.code : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      levelorg: { code: e.target.value },
                    })
                  }
                >
                  <option value="">Pilih Level Organisasi</option>
                  {dataOrg.length &&
                    dataOrg.map((d) => (
                      <option key={d.code} value={d.code}>
                        {d.des}
                      </option>
                    ))}
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = ['No', 'Code', 'Name', 'Address', 'Action'];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isCode = false }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td>{d.code}</td>
        <td>{d.name}</td>
        <td>{excerpt(d.addr, 120)}</td>
        <td>
          <a href={d.link} target="_blank" rel="noreferrer">
            {d.link}
          </a>
        </td>
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function Org() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('organisations', fetcherAuth);

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataWC =
    data &&
    data.result.map((d, i) => {
      return { ...d, no: i + 1 };
    });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteOrg(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Organisasi</h2>
      <hr />
      <button
        onClick={() => {
          setIsShow(true);
          setIsEdit(false);
          setEditData(null);
        }}
        type="button"
        className="btn btn-primary mb-2"
        title="Add Organisasi"
      >
        Add
      </button>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
            isCode={true}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <AddEditOrg
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
