import './cms.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Aside from './components/Aside';
import { useEffect } from 'react';

export default function CMSLayout() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userData'))?.token;
    if (!token) navigate('/login');
  }, [navigate]);

  return (
    <div className="cms-container">
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 p-0">
            <Aside />
          </div>
          <div className="col-10 p-3">
            <main>
              <Outlet />
            </main>
          </div>
        </div>
      </div>
      <div className="restricted">
        {/* <h4>CMS Hanya bisa diakses di desktop.</h4> */}
        <img src="/assets/images/desktop-only.jpg" alt="desktop-only" />
      </div>
    </div>
  );
}
