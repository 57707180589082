import { useEffect, useState } from 'react';
import Banner from './components/Banner';
import Pagination from './components/Pagination';
import Gallery from './components/Gallery';
import { fetcher } from '../services';
import useSWR from 'swr';
import { abjads, scrollToTop } from '../helper';

export default function Merchandise() {
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [selectedMerch, setSelectedMerch] = useState(0);
  const [searchName, setSearchName] = useState('');
  const [abjad, setAbjad] = useState(searchName ? false : true);
  const [abjadName, setAbjadName] = useState('');
  const [category, setCategory] = useState('');
  const [url, setUrl] = useState(`merchandises?limit=${limit}&page=${page}`);
  const { data } = useSWR(url, fetcher);
  const { data: dataCategory } = useSWR(`kategorimrcs`, fetcher);
  const { data: dataBanner } = useSWR('banners?menu=merchandise', fetcher);
  const [isShowGallery, setIsShowGallery] = useState(false);

  const filterData = (e) => {
    e.preventDefault();
    setPage(0);
    setUrl(
      `merchandises?limit=${limit}&page=${0}&search_name=${searchName}&search_ktg=${category}`
    );
  };

  const searchByCategory = (data) => {
    setCategory(data);
    setPage(0);
    setUrl(
      `merchandises?limit=${limit}&page=${0}&search_name=${searchName}&search_ktg=${data}`
    );
  };

  const searchByAbjad = (data) => {
    setSelectedMerch(0);
    setAbjadName(data);
    setPage(0);
    setUrl(
      `merchandises?limit=${limit}&page=${0}&search_name=${searchName}&search_inisial=${data}`
    );
  };

  const handleChangePage = (e) => {
    setPage(e);
    setUrl(
      `merchandises?limit=${limit}&page=${e}&search_name=${searchName}&search_ktg=${category}&search_inisial=${abjadName}`
    );
    scrollToTop();
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setPage(0);
    setUrl(
      `merchandises?limit=${e}&page=${0}&search_name=${searchName}&search_ktg=${category}&search_inisial=${abjadName}`
    );
    scrollToTop();
  };

  useEffect(() => {
    if (searchName) {
      setAbjad(false);
    } else {
      setAbjad(true);
    }
  }, [searchName]);

  return (
    <div className="merchandise">
      {dataBanner && <Banner images={dataBanner.result} />}
      <section>
        <h2>Merchandise</h2>
        <div className="container">
          <form className="row" onSubmit={filterData}>
            <div className="col-md-6 mb-1">
              <input
                disabled={abjadName}
                type="text"
                className="form-control search"
                placeholder="Cari Nama Barang"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <button type="submit" className="btn btn-danger btn-sm mb-3">
                Cari
              </button>
            </div>
          </form>
          <div className="categories">
            <button
              onClick={() => searchByCategory('')}
              className={category === '' ? 'btn-pill active' : 'btn-pill'}
            >
              Semua
            </button>
            {dataCategory &&
              dataCategory.result.map((c) => (
                <button
                  onClick={() => searchByCategory(c.id)}
                  key={c.id}
                  className={category === c.id ? 'btn-pill active' : 'btn-pill'}
                >
                  {c.des}
                </button>
              ))}
          </div>
          <div className="filter">
            {abjad && (
              <>
                <span
                  className={abjadName === '' ? 'active' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => searchByAbjad('')}
                >
                  Semua
                </span>
                <div className="alphabet">
                  {abjads.map((a) => (
                    <span
                      key={a.name}
                      className={abjadName === a.name ? 'active' : ''}
                      style={{ cursor: 'pointer' }}
                      onClick={() => searchByAbjad(a.name)}
                    >
                      {a.name}
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="member-list merch-list">
            {data && data.result.length ? (
              data.result.map((d, i) => (
                <div
                  key={d.id}
                  className="member-list-item"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedMerch(i);
                    setIsShowGallery(true);
                  }}
                >
                  <img src={d.url_image.split(',')[0]} alt="member" />
                  <div>
                    <h5>
                      <span>{d.name}</span>
                    </h5>
                    <p>{d.desc}</p>
                    {/* <p>
                      IDR {d.price ? d.price : 'XXX'} / {d.unit}
                    </p> */}
                    <p>IDR XXX / {d.unit}</p>
                    <p>
                      Informasi lebih lanjut & Pemesanan, <br /> hubungi
                      <b> {d.contact}</b>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>Data Kosong</p>
            )}
          </div>
          {data && data.result.length ? (
            <Pagination
              data={data}
              onChangeLimit={(e) => handleChangeLimit(e)}
              onChangePage={(e) => handleChangePage(e)}
              limit={limit}
              page={page}
            />
          ) : (
            <div></div>
          )}
        </div>
      </section>
      {data && data.result.length && (
        <Gallery
          isShow={isShowGallery}
          onClose={() => setIsShowGallery(false)}
          title={data.result.name}
          images={data.result[selectedMerch].url_image
            .split(',')
            .map((img, i) => ({ id: i + 1, source: img }))}
        />
      )}
    </div>
  );
}
