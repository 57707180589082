import { navs } from '../../helper';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAdmin } from '../../services';

export default function Aside() {
  const myNavs = isAdmin()
    ? [
        ...navs,
        {
          id: 99,
          label: 'User',
          path: '/cms/user',
          childs: [],
        },
      ]
    : [...navs];
  return (
    <aside className="text-bg-secondary sticky-top">
      <nav>
        <ul className="nav flex-column">
          {myNavs.map((nav) => (
            <li className="nav-item" key={nav.id}>
              {nav.childs.length ? (
                <>
                  {/* <a className="nav-link text-white">{nav.label}</a> */}
                  <NavLink
                    className="nav-link text-white"
                    to={nav.path}
                    end={nav.path === '/cms'}
                  >
                    {nav.label}{' '}
                    <span className="arrow">
                      <FontAwesomeIcon icon="chevron-down" />
                    </span>
                  </NavLink>
                  <ul className="nav flex-column child">
                    {nav.childs.map((child) => (
                      <li className="nav-item" key={child.id}>
                        <NavLink
                          className="nav-link text-white"
                          to={child.path}
                          end
                        >
                          {child.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <NavLink
                  className="nav-link text-white"
                  to={nav.path}
                  end={nav.path === '/cms'}
                >
                  {nav.label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}
