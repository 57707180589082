export default function NotFound() {
  return (
    <div style={{ textAlign: 'center', padding: '4rem' }}>
      <h2>
        Halaman tidak ditemukan atau Anda tidak memliki akses ke halaman ini
      </h2>
      <h6>Atau refresh browser jika anda sudah login</h6>
    </div>
  );
}
