import Slider from 'react-slick';
import Banner, { NextArrow, PrevArrow } from './components/Banner';
import useSWR from 'swr';
import { fetcher } from '../services';

function WelcomeVid() {
  const { data } = useSWR('sambutanvideo', fetcher);
  return (
    <section className="videos">
      <h2>Sambutan Ketua Umum</h2>
      <div className="container video text-center mt-5 mb-4">
        <iframe
          title="karaete-vid1"
          width="1080"
          height="600"
          src={data && data.result[0].url_video}
          // src="https://www.youtube.com/embed/tgbNymZ7vqY"
        ></iframe>
      </div>
    </section>
  );
}

function OrgStructure() {
  const { data } = useSWR('pengurus', fetcher);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // responsive: [
    //   // {
    //   //   breakpoint: 575,
    //   //   settings: {
    //   //     slidesToShow: 3,
    //   //     slidesToScroll: 3,
    //   //   },
    //   // },
    //   // {
    //   //   breakpoint: 360,
    //   //   settings: {
    //   //     slidesToShow: 3,
    //   //     slidesToScroll: 3,
    //   //   },
    //   // },
    // ],
  };

  return (
    <section className="org-structure">
      <h2>Struktur Organisasi</h2>
      <div className="container">
        <Slider {...settings}>
          {data &&
            data.result
              .filter((d) => d.level === '1')
              .map((d) => (
                <div className="item" key={d.id}>
                  <img
                    src={
                      d.anggotum.url_foto
                        ? d.anggotum.url_foto
                        : 'https://apidev.budokaijakarta.org/anggota/photo_6258075209689052837_m.jpg'
                    }
                    alt="ava"
                  />
                  <h6>{d.anggotum.name}</h6>
                  <div className="jabatan-org">
                    <p>{d.Jabatan.des}</p>
                    <p>{d.organisation.name}</p>
                  </div>
                </div>
              ))}
        </Slider>
        <hr />
        <Slider {...settings}>
          {data &&
            data.result
              .filter((d) => d.level === '2')
              .map((d) => (
                <div className="item" key={d.id}>
                  <img
                    src={
                      d.anggotum.url_foto
                        ? d.anggotum.url_foto
                        : 'https://apidev.budokaijakarta.org/anggota/photo_6258075209689052837_m.jpg'
                    }
                    alt="ava"
                  />
                  <h6>{d.anggotum.name}</h6>
                  <div className="jabatan-org">
                    <p>{d.Jabatan.des}</p>
                    <p>{d.organisation.name}</p>
                  </div>
                </div>
              ))}
        </Slider>
        <hr />
        <Slider {...settings}>
          {data &&
            data.result
              .filter((d) => d.level === '3')
              .map((d) => (
                <div className="item" key={d.id}>
                  <h6>{d.anggotum.name}</h6>
                  <div className="jabatan-org">
                    <p>{d.Jabatan.des}</p>
                    <p>{d.organisation.name}</p>
                  </div>
                </div>
              ))}
        </Slider>
      </div>
    </section>
  );
}

function LogoMeaning() {
  const { data } = useSWR('maknalambang', fetcher);
  return (
    <section className="logo-meaning">
      <h2>{data && data.result[0].tittle}</h2>
      <div className="container">
        {data && <img width={270} src={data.result[0].url_img} alt="logo" />}
        {/* <div>
          <div>
            <h6>Warna Merah</h6>
            <p>
              Warna merah menunjukan energi, semangat dan keberanian BUDOKAI
              untuk menjadi motor pengerak kemajuan karate di Indonesia, yang
              memadukan unsur tradisi, ilmu olahraga, dan industri olahraga
              secara terpadu.
            </p>
          </div>
          <div>
            <h6>Warna Putih</h6>
            <p>
              Warna putih menunjukan keterbukaan BUDOKAI dalam menyikapi
              dinamika yang ada dalam dunia Karate, baik dalam hal teknik, gaya,
              maupun aliran.
            </p>
          </div>
          <div>
            <h6>Warna Hitam</h6>
            <p>
              Warna hitam menujukan ketegasan sekaligus kepercayaan diri yang
              kuat dari BUDOKAI untuk selalu melakukan inovasi dan terobosan
              dalam memajukan karate Indonesia.
            </p>
          </div>
          <div>
            <h6>Warna Hijau</h6>
            <p>
              Warna hijau menunjukan harmoni dan keseimbangan dalam aktifitas
              karate sebagai instrumen pendidikan karakter, kesehatan dan
              prestasi secara berkesinambungan yang ingin dibangun oleh BUDOKAI
              dalam memajukan karate Indonesia.
            </p>
          </div>
          <div>
            <h6>Warna Kuning</h6>
            <p>
              Warna kuning menunjukan optimisme BUDOKAI dalam mencapai visi dan
              misi organisasi sebagai bagian dari usaha memajukan Bangsa
              Indonesia secara keseluruhan.
            </p>
          </div>
        </div> */}
        {data && <div dangerouslySetInnerHTML={{ __html: data.result[0].des }}></div>}
      </div>
    </section>
  );
}

function VisiMisi() {
  const { data: dataVisi } = useSWR('visi', fetcher);
  const { data: dataMisi } = useSWR('misi', fetcher);
  return (
    <section className="visi-misi">
      {/* <div className="container">
          <div className="visi">
            <h6>Visi</h6>
            <p>
              Menjadi pionir perkembangan pendidikan dan pelatihan seni-beladiri
              karate di Indonesia guna mewujudkan manusia Indonesia yang unggul
              dan berkarakter.
            </p>
          </div>
          <div className="Misi">
            <h6>Misi</h6>
            <div>
              Pasal 9
              <p>
                (1) Mengedepankan nilai-nilai profesionalisme dalam
                penyelenggaraan pendidikan dan pelatihan beladiri karate;
              </p>
              <p>
                (2) Menyelenggarakan pendidikan dan pelatihan beladiri karate yang
                transformasional yang berakar pada sikap ksatria (bushido);
              </p>
              <p>
                (3) Berkontribusi aktif dalam pembentukan karakter anak bangsa
                dengan melakukan pembinaan yang komprehensif dalam mewujudkan
                cita-cita bangsa Indonesia; dan
              </p>
              <p>
                (4) Membantu seluruh anggotanya untuk mengoptimalkan potensi
                terbaik dalam dirinya.
              </p>
            </div>
          </div>
        </div> */}
      <h2>Visi & Misi Budokai</h2>
      <div className="container">
        {dataVisi && <div className="visi" dangerouslySetInnerHTML={{ __html: dataVisi.result[0].des }}></div>}
        {dataMisi && <div className="misi" dangerouslySetInnerHTML={{ __html: dataMisi.result[0].des }}></div>}
      </div>
    </section>
  );
}

function Mars() {
  const { data } = useSWR('mars', fetcher);
  return (
    <section className="mars">
      <h2>{data && data.result[0].tittle}</h2>
      <div className="container">
        <div className="video text-center mt-5 mb-4">
          <iframe title="karaete-vid1" width="1080" height="600" src={data && data.result[0].url_video}></iframe>
        </div>
        <div className="mars-text">
          {/* <p>{data && data.result[0].des}</p> */}
          <p dangerouslySetInnerHTML={{ __html: data ? data.result[0].des : '' }}></p>
          {/* <p>
            Kami anggota BUDOKAI Memiliki..ji..wa yang ksatria Tangguh cakap dan
            terlatih Itulah yang ter..tanam dalam jiwa
          </p>
          <p>
            Kami anggota BUDOKAI Mengikuti a..ja..ran bushido Yang mem..bentuk
            karakter diri Disiplin serta bertanggung jawab
          </p>
          <p>
            Kami...menjunjung tinggi... Norma lu...hur budi pekerti Kembangkan
            daya tahan tubuh Menja...ga sportifitas
          </p>
          <p>
            BUDO...KAI...BUDOKAI Bersamamu...kami berprestasi
            BUDO...KAI...BUDOKAI... Kaulah...yang kami bangga...kan...
          </p> */}
        </div>
      </div>
    </section>
  );
}

export default function About() {
  const { data } = useSWR('banners?menu=about', fetcher);
  return (
    <div className="about">
      {data && <Banner images={data.result} />}
      {/* <WelcomeVid /> */}
      <OrgStructure />
      <LogoMeaning />
      <VisiMisi />
      <Mars />
    </div>
  );
}
