import { useState } from 'react';

export default function Pagination(props) {
  const [pages, setPages] = useState(
    Array.from(Array(props.data.totalPage).keys()).filter((d) =>
      props.data.page === 0
        ? d < 3
        : d === props.data.page - 1 ||
          d === props.data.page ||
          d === props.data.page + 1
    )
  );

  const onChangeLimit = (e) => {
    props.onChangeLimit(e.target.value);
  };

  const onChangePage = (p) => {
    props.onChangePage(p);
  };

  return (
    <div className="pagination-container">
      <div className="item-per-page">
        <select name="itemPerPage" onChange={onChangeLimit} value={props.limit}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
        </select>
      </div>
      <div className="pagination">
        {props.data.page > 0 && (
          <>
            <button className="first" onClick={() => onChangePage(0)}>
              <img src="assets/images/page-darrow-left.png" alt="arrow" />
            </button>
            <button
              className="prev"
              onClick={() => onChangePage(props.data.page - 1)}
            >
              <img src="assets/images/page-arrow-left.png" alt="arrow" />
            </button>
          </>
        )}

        <span className="numbers">
          {pages.map((d) => {
            return props.data.page === d ? (
              <button className={props.data.page === d ? 'active' : ''} key={d}>
                {d + 1}
              </button>
            ) : (
              <button onClick={() => onChangePage(d)} key={d}>
                {d + 1}
              </button>
            );
          })}
        </span>

        {props.data.page + 1 < props.data.totalPage && (
          <>
            <button
              className="next"
              onClick={() => onChangePage(props.data.page + 1)}
            >
              <img src="assets/images/page-arrow-right.png" alt="arrow" />
            </button>
            <button
              className="last"
              onClick={() => onChangePage(props.data.totalPage - 1)}
            >
              <img src="assets/images/page-darrow-right.png" alt="arrow" />
            </button>
          </>
        )}
      </div>
      <div className="total">
        <p>
          <span>{props.data.result.length}</span> from{' '}
          <span>{props.data.totalRows}</span> Data
        </p>
      </div>
    </div>
  );
}
