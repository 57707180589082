import { useEffect, useState } from 'react';
import { addContact, updateContact } from '../../../services';

export default function AddEditContact({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    addr: '',
    phone: '',
    email: '',
    email2: '',
    instagram: '',
    facebook: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
  }, [editData]);

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('addr', formData.addr);
    formDataObj.append('phone', formData.phone);
    formDataObj.append('email', formData.email);
    formDataObj.append('email2', formData.email2 ? formData.email2 : '');
    formDataObj.append('instagram', formData.instagram ? formData.instagram : '');
    formDataObj.append('facebook', formData.facebook ? formData.facebook : '');
    try {
      const response = isEdit ? await updateContact(formDataObj, 1) : await addContact(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      setFormData({
        addr: '',
        phone: '',
        email: '',
        email2: '',
        instagram: '',
        facebook: '',
      });
      setIsShow(false);
    }
  };

  const isFormEmpty = () => {
    let result = false;
    let obj = { ...formData, email2: 'x', instagram: 'x', facebook: 'x' };

    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
    // return !formData.menu;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => setIsShow(false)}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Contact</h1>
            <button type="button" className="btn-close" onClick={() => setIsShow(false)}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="addr" className="form-label">
                  Address
                </label>
                <textarea
                  id="addr"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={formData.addr ? formData.addr : ''}
                  onChange={(e) => setFormData({ ...formData, addr: e.target.value })}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  value={formData.phone ? formData.phone : ''}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  value={formData.email ? formData.email : ''}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email2" className="form-label">
                  Email 2
                </label>
                <input
                  type="email2"
                  id="email2"
                  className="form-control"
                  value={formData.email2 ? formData.email2 : ''}
                  onChange={(e) => setFormData({ ...formData, email2: e.target.value })}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="instagram" className="form-label">
                  Instagram
                </label>
                <input
                  type="text"
                  id="instagram"
                  className="form-control"
                  value={formData.instagram ? formData.instagram : ''}
                  onChange={(e) => setFormData({ ...formData, instagram: e.target.value })}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor="facebook" className="form-label">
                  Facebook
                </label>
                <input
                  type="text"
                  id="facebook"
                  className="form-control"
                  value={formData.facebook ? formData.facebook : ''}
                  onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => setIsShow(false)}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
