import './web.scss';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';

export default function WebLayout() {
  return (
    <div className="web-container">
      <Header />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </div>
  );
}
