import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { addNews, deleteNews, fetcherAuth, updateNews } from '../../../services';
import { excerpt } from '../../../helper';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputImage from '../../components/InputImage';
import Pagination from '../../components/Pagnation';

function AddEditNews({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    tittle: '',
    tag: '',
    url_image1: '',
    url_image2: '',
  });
  const [des, setDes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
    setDes(editData && editData.body && editData.body);
  }, [editData]);

  const clearForm = () => {
    setFormData({
      tittle: '',
      tag: '',
      url_image1: '',
      url_image2: '',
    });
    setDes('');
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('tittle', formData.tittle);
    formDataObj.append('body', des);
    formDataObj.append('tag', formData.tag);
    // formDataObj.append('link', formData.link);
    typeof formData.url_image1 === 'object'
      ? formDataObj.append('img1', formData.url_image1)
      : formDataObj.append('img1', '');
    typeof formData.url_image2 === 'object'
      ? formDataObj.append('img2', formData.url_image2)
      : formDataObj.append('img2', '');

    try {
      const response = isEdit ? await updateNews(formDataObj, editData.id) : await addNews(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;

    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries({
      tittle: formData.tittle,
      tag: formData.tag,
      url_image1: formData.url_image1,
      url_image2: formData.url_image1,
    })) {
      if (value === '') {
        result = true;
        break;
      }
    }

    return result;
  };

  const onChangeDesc = (value) => {
    setDes(value);
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Informasi</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="tittle" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  id="tittle"
                  className="form-control"
                  value={formData.tittle ? formData.tittle : ''}
                  onChange={(e) => setFormData({ ...formData, tittle: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="body" className="form-label">
                  Content
                </label>
                <ReactQuill theme="snow" value={des ? des : ''} onChange={onChangeDesc} />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="tag" className="form-label">
                  Tag
                </label>
                <input
                  type="text"
                  id="tag"
                  className="form-control"
                  value={formData.tag ? formData.tag : ''}
                  onChange={(e) => setFormData({ ...formData, tag: e.target.value })}
                />
              </div>
              {/* <div className="form-group mb-3">
                <label htmlFor="link" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  id="link"
                  className="form-control"
                  value={formData.link ? formData.link : ''}
                  onChange={(e) =>
                    setFormData({ ...formData, link: e.target.value })
                  }
                />
              </div> */}
              <div className="form-group mb-3">
                <label className="form-label">Banner Image</label>
                <InputImage
                  imgSrc={formData.url_image1 ? formData.url_image1 : ''}
                  name="url_image1"
                  changeInputImage={(e) => setFormData({ ...formData, url_image1: e.target.files[0] })}
                />
                {/* <input
                  className="form-control"
                  type="file"
                  id="img1"
                  onChange={(e) =>
                    setFormData({ ...formData, img1: e.target.files[0] })
                  }
                ></input> */}
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Thumbnail Image</label>
                <InputImage
                  imgSrc={formData.url_image2 ? formData.url_image2 : ''}
                  name="url_image2"
                  changeInputImage={(e) => setFormData({ ...formData, url_image2: e.target.files[0] })}
                />
                {/* <input
                  className="form-control"
                  type="file"
                  id="img2"
                  onChange={(e) =>
                    setFormData({ ...formData, img2: e.target.files[0] })
                  }
                ></input> */}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = [
  'No',
  'Title',
  'Body',
  'Banner',
  'Thumbnail',
  // 'Link',
  'Action',
];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isCode = false }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td>{d.tittle}</td>
        <td>{excerpt(d.body, 60)}</td>
        <td>
          <img src={d.url_image1} alt="" width={80} />
        </td>
        <td>
          <img src={d.url_image2} alt="" width={80} />
        </td>
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function NewsCMS() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });

  // Pagination
  const itemsPerPage = 5;
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [url, setUrl] = useState(`beritas?limit=${itemsPerPage}&page=${page}&order_by=DESC`);
  const { data, mutate } = useSWR(url, fetcherAuth);
  const dataWC =
    data &&
    data.result.map((d, i) => {
      return { ...d, no: page * itemsPerPage + (i + 1) };
    });
  const currentItems = dataWC && dataWC;
  const pageCount = Math.ceil(data && data.totalRows / itemsPerPage);

  const handlePageClick = (event) => {
    setPage(event.selected);
    setUrl(`beritas?limit=${itemsPerPage}&page=${event.selected}&order_by=DESC&search_query=${searchName}`);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteNews(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleItemsSearch = () => {
    setPage(0);
    setUrl(`beritas?limit=${itemsPerPage}&page=0&order_by=DESC&search_query=${searchName}`);
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Informasi</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Informasi"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <input type="text" className="form-control" onChange={(e) => setSearchName(e.target.value)} />
          <button className="btn btn-warning" onClick={handleItemsSearch}>
            Search
          </button>
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} page={page} />
      <AddEditNews
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
