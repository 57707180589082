import { useEffect, useState } from 'react';
import {
  addDojo,
  addPic,
  deleteDojo,
  deletePic,
  downloadDojo,
  fetcher,
  fetcherAuth,
  updateDojo,
} from '../../../services';
import useSWR from 'swr';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';
import InputImage from '../../components/InputImage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const columns = ['No', 'Name', 'Address', 'Action'];

function PICs({ isPICShow, setIsPICShow, dojo, onDelete, onSave }) {
  const [isAdd, setIsAdd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataAnggota, setDataAnggota] = useState([]);
  const [anggotaId, setAnggotaId] = useState(undefined);
  const [searchName, setSearchName] = useState('');

  const getAnggota = (limit = 100, name = '') => {
    fetcher(`anggotas?limit=${limit}&search_name=${name}`).then((res) => {
      setDataAnggota(res.result);
    });
  };

  const handleReset = () => {
    setSearchName('');
    setDataAnggota([]);
    setAnggotaId(undefined);
    setIsAdd(false);
    setIsSaving(false);
    setIsPICShow(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await addPic({
        dojoid: dojo.id,
        anggotaid: anggotaId,
      });
      if (response) {
        onSave(response);
      }
    } catch (err) {
      console.log(err);
      onSave(err);
    } finally {
      handleReset();
    }
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deletePic(id);
      if (response) {
        onDelete(response);
      }
    } catch (err) {
      console.log(err);
      onDelete(err);
    } finally {
      setIsDeleting(false);
      handleReset();
    }
  };

  return (
    <div className={isPICShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => setIsPICShow(false)}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">PICs</h1>
            <button type="button" className="btn-close" onClick={handleReset}></button>
          </div>
          <div className="modal-body">
            <button className="btn btn-primary mb-2" onClick={() => setIsAdd(!isAdd)}>
              Add
            </button>
            {isAdd && (
              <div className="card mb-2">
                <div className="card-body">
                  <div className="form-group mb-3">
                    <label htmlFor="body" className="form-label">
                      Anggota
                    </label>
                    <div className="d-flex gap-2 align-item-center mb-2">
                      <input
                        type="text"
                        className="form-control"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                      <button
                        disabled={!searchName}
                        className="btn btn-primary"
                        type="button"
                        onClick={() => getAnggota(100, searchName)}
                      >
                        Cari
                      </button>
                    </div>
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <select
                        disabled={!dataAnggota.length}
                        className="form-select"
                        value={anggotaId}
                        onChange={(e) => setAnggotaId(e.target.value)}
                      >
                        <option value="">Pilih Anggota</option>
                        {dataAnggota.length &&
                          dataAnggota.map((d) => (
                            <option key={d.id} value={d.id}>
                              {d.name}
                            </option>
                          ))}
                      </select>
                      <button className="btn btn-warning" onClick={handleSave} disabled={isSaving}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ul className="list-group">
              {dojo &&
                dojo.dojopics &&
                dojo.dojopics.map((d) => (
                  <li className="list-group-item d-flex justify-content-between align-items-center" key={d.id}>
                    {d.anggotum.name}
                    <button className="btn btn-danger" disabled={isDeleting} onClick={() => handleDelete(d.id)}>
                      X
                    </button>
                  </li>
                ))}
            </ul>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={handleReset}>
              Close
            </button>
            {/* <button type="submit" className="btn btn-primary">
              Save changes
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function AddEditDojo({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    addr: '',
    gmap: '',
    kontak: '',
    jadwal: '',
    sosmed: '',
    organisation: { id: '' },
    dojopics: '',
    url_image: '',
  });
  const [jadwal, setJadwal] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');
  const [image5, setImage5] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataOrg, setDataOrg] = useState([]);

  useEffect(() => {
    editData && setFormData(editData);
    setJadwal(editData && editData.jadwal && editData.jadwal);
    if (editData && editData.url_image) {
      let images = editData.url_image.split(',');
      setImage1(images[0] ? images[0] : '');
      setImage2(images[1] ? images[1] : '');
      setImage3(images[2] ? images[2] : '');
      setImage4(images[3] ? images[3] : '');
      setImage5(images[4] ? images[4] : '');
    }
  }, [editData]);

  const getOrg = () => {
    fetcher(`organisations`).then((res) => {
      setDataOrg(res.result);
    });
  };

  useEffect(() => {
    getOrg();
  }, []);

  const clearForm = () => {
    setFormData({
      name: '',
      addr: '',
      gmap: '',
      kontak: '',
      jadwal: '',
      sosmed: '',
      organisation: { id: '' },
      dojopics: '',
      url_image: '',
    });
    setJadwal('');
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('addr', formData.addr);
    formDataObj.append('gmap', formData.gmap);
    formDataObj.append('kontak', formData.kontak);
    formDataObj.append('jadwal', jadwal);
    formDataObj.append('sosmed', formData.sosmed);
    formDataObj.append('organisationid', formData.organisation.id);
    // formDataObj.append('pic', formData.dojopics);
    typeof image1 === 'object' && formDataObj.append('image1', image1);
    typeof image2 === 'object' && formDataObj.append('image2', image2);
    typeof image3 === 'object' && formDataObj.append('image3', image3);
    typeof image4 === 'object' && formDataObj.append('image4', image4);
    typeof image4 === 'object' && formDataObj.append('image5', image5);

    try {
      const response = isEdit ? await updateDojo(formDataObj, editData.id) : await addDojo(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;

    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries({
      name: formData.name,
      addr: formData.addr,
      organisation: formData.organisation.id,
    })) {
      if (value === '') {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Dojo</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={formData.name ? formData.name : ''}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="addr" className="form-label">
                  Address
                </label>
                <textarea
                  id="addr"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={formData.addr ? formData.addr : ''}
                  onChange={(e) => setFormData({ ...formData, addr: e.target.value })}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="gmap" className="form-label">
                  Map
                </label>
                <input
                  type="text"
                  id="gmap"
                  className="form-control"
                  value={formData.gmap ? formData.gmap : ''}
                  onChange={(e) => setFormData({ ...formData, gmap: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="kontak" className="form-label">
                  Contact
                </label>
                <input
                  type="text"
                  id="kontak"
                  className="form-control"
                  value={formData.kontak ? formData.kontak : ''}
                  onChange={(e) => setFormData({ ...formData, kontak: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="jadwal" className="form-label">
                  Jadwal
                </label>
                {/* <input
                  type="text"
                  id="jadwal"
                  className="form-control"
                  value={formData.jadwal ? formData.jadwal : ''}
                  onChange={(e) =>
                    setFormData({ ...formData, jadwal: e.target.value })
                  }
                /> */}
                <ReactQuill
                  theme="snow"
                  value={jadwal ? jadwal : ''}
                  onChange={(value) => setJadwal(value)}
                  modules={{
                    toolbar: [
                      [{ font: [] }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ color: [] }, { background: [] }],
                      [{ script: 'sub' }, { script: 'super' }],
                      ['blockquote', 'code-block'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
                      ['clean'],
                    ],
                  }}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="sosmed" className="form-label">
                  Sosmed (IG)
                </label>
                <input
                  type="text"
                  id="sosmed"
                  className="form-control"
                  value={formData.sosmed ? formData.sosmed : ''}
                  onChange={(e) => setFormData({ ...formData, sosmed: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Organisasi</label>
                <select
                  className="form-select"
                  value={formData.organisation.id ? formData.organisation.id : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      organisation: { id: e.target.value },
                    })
                  }
                >
                  <option value="">Pilih Organisasi</option>
                  {dataOrg.length &&
                    dataOrg.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 1</label>
                <InputImage
                  imgSrc={image1 ? image1 : ''}
                  name="image1"
                  changeInputImage={(e) => setImage1(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 2</label>
                <InputImage
                  imgSrc={image2 ? image2 : ''}
                  name="image2"
                  changeInputImage={(e) => setImage2(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 3</label>
                <InputImage
                  imgSrc={image3 ? image3 : ''}
                  name="image3"
                  changeInputImage={(e) => setImage3(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 4</label>
                <InputImage
                  imgSrc={image4 ? image4 : ''}
                  name="image4"
                  changeInputImage={(e) => setImage4(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 5</label>
                <InputImage
                  imgSrc={image5 ? image5 : ''}
                  name="image5"
                  changeInputImage={(e) => setImage5(e.target.files[0])}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Row({
  result,
  isDeleting,
  onIsShow,
  onIsPICShow,
  onIsEdit,
  onEditData,
  onDeleteRow,
  isLoading,
  isCode = false,
}) {
  return isLoading ? (
    <tr>
      <td colSpan="4">Loading...</td>
    </tr>
  ) : (
    result &&
      result.map((d) => (
        <tr key={isCode ? d.code : d.id}>
          <td>{d.no}</td>
          <td>{d.name}</td>
          <td>{d.addr}</td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                onIsShow(true);
                onIsEdit(true);
                onEditData(d);
              }}
            >
              Edit
            </button>
            &nbsp;
            <button
              disabled={isDeleting}
              onClick={() => onDeleteRow(isCode ? d.code : d.id)}
              className="btn btn-sm btn-danger"
              title="Delete"
            >
              Delete
            </button>
            &nbsp;
            <button
              className="btn btn-sm btn-info"
              onClick={() => {
                onIsPICShow(d);
              }}
            >
              PICs
            </button>
          </td>
        </tr>
      ))
  );
}

export default function Dojos() {
  const [isShow, setIsShow] = useState(false);
  const [isPICShow, setIsPICShow] = useState(false);
  const [dojo, setDojo] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });

  // Pagination
  const itemsPerPage = 5;
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);

  const [url, setUrl] = useState(`dojos?limit=${itemsPerPage}&page=${page}`);
  const { data, mutate, isLoading } = useSWR(url, fetcherAuth);
  const dataWC =
    data &&
    data.result.map((d, i) => {
      return { ...d, no: page * itemsPerPage + (i + 1) };
    });
  const currentItems = dataWC && dataWC;
  const pageCount = data ? Math.ceil(data.totalRows / itemsPerPage) : 0;

  const handlePageClick = (event) => {
    setPage(event.selected);
    setUrl(`dojos?limit=${itemsPerPage}&page=${event.selected}&search_name=${searchName}`);
  };

  const handleItemsSearch = () => {
    setPage(0);
    setUrl(`dojos?limit=${itemsPerPage}&page=0&search_name=${searchName}`);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteDojo(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Dojo</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Dojo"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <input type="text" className="form-control" onChange={(e) => setSearchName(e.target.value)} />
          <button className="btn btn-warning" onClick={handleItemsSearch}>
            Search
          </button>
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsPICShow={(e) => {
              setDojo(e);
              setIsPICShow(true);
            }}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
            isLoading={isLoading}
          />
        }
      />
      <div className="d-flex align-items-center justify-content-between">
        <Pagination pageCount={pageCount} onPageClick={handlePageClick} page={page} />
        <a title="Download" className="btn btn-danger" href={downloadDojo} download={downloadDojo}>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </div>
      <AddEditDojo
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
      <PICs
        dojo={dojo}
        isPICShow={isPICShow}
        setIsPICShow={(e) => setIsPICShow(e)}
        onDelete={handleSubmit}
        onSave={handleSubmit}
      />
    </div>
  );
}

// https://apidev.budokaijakarta.org/dojo/57
