export default function Table({ children, columns = [] }) {
  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          {columns.map((column, i) => (
            <th key={i}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
