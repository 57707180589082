export default function Info({ type = 'light', text, onClose }) {
  return (
    <div className={`alert alert-${type} d-flex justify-content-between form-alert`} role="alert">
      {text}
      <span onClick={onClose} style={{ cursor: 'pointer', marginLeft: '1rem' }}>
        x
      </span>
    </div>
  );
}
