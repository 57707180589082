import { useState } from 'react';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';
import useSWR from 'swr';
import { fetcherAuth } from '../../../services';
import AddEditUser from './AddEditUser';
import { deleteUser } from '../../../services';
import Info from '../../../components/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';

const columns = ['No', 'Name', 'Email', 'Role', 'Action'];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isCode = false }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.uuid}>
        <td>{d.no}</td>
        <td>{d.name}</td>
        <td>{d.email}</td>
        <td>{d.role}</td>
        {/* <td>{d.isactive === 'Y' ? 'Yes' : 'No'}</td> */}
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.uuid)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function User() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sort, setSort] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchData, setSearchData] = useState('');
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('users', fetcherAuth);

  const sortAsc = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const sortDesc = (a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataList = data ? [...data.result] : [];
  const sortedData = dataList && dataList.length && sort ? dataList.sort(sortDesc) : dataList.sort(sortAsc);
  const dataWC =
    sortedData &&
    sortedData
      .filter((d) => {
        let pattern = new RegExp(searchData, 'ig');
        return pattern.test(d.name) || pattern.test(d.email) || pattern.test(d.role);
      })
      .map((d, i) => {
        return { ...d, no: i + 1 };
      });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (uuid) => {
    setIsDeleting(true);
    try {
      const response = await deleteUser(uuid);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  const onSort = () => {
    setSort(!sort);
  };

  return (
    <div>
      <h2>User</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <button
            onClick={() => {
              setIsShow(true);
              setIsEdit(false);
              setEditData(null);
            }}
            type="button"
            className="btn btn-primary mb-2"
            title="Add User"
          >
            Add
          </button>
          <button className="btn btn-warning mb-2" onClick={onSort}>
            {sort ? <FontAwesomeIcon icon={faArrowDownZA} /> : <FontAwesomeIcon icon={faArrowDownAZ} />}
          </button>
        </div>
        <div className="d-flex align-items-center gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Name, Email or Role"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <AddEditUser
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
