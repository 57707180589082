import axios from 'axios';

// const apiURL = 'https://apidev.budokaijakarta.org';
const apiURL = 'https://api.budokaijakarta.org';

export const downloadMerch = 'https://apidev.budokaijakarta.org/downloadmerchandise';
export const downloadDojo = 'https://apidev.budokaijakarta.org/downloaddojo';
export const downloadAnggota = 'https://apidev.budokaijakarta.org/downloadanggota';

const getToken = () => {
  const userData = localStorage.getItem('userData');
  if (userData) return JSON.parse(userData).token;
  return '';
};

export const isAdmin = () => {
  const userData = localStorage.getItem('userData');
  if (JSON.parse(userData) && JSON.parse(userData).role === 'admin') return true;
  return false;
};

const getHeaders = () => {
  return {
    headers: { 'x-access-token': getToken() },
  };
};

export const fetcher = (params) => axios.get(`${apiURL}/${params}`).then((res) => res.data);

export const fetcherYT = (url) => axios.get(url).then((res) => res.data);

export const fetcherAuth = (params) =>
  axios
    .get(`${apiURL}/${params}`, getHeaders())
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('userData');
        window.location.replace('/login');
      }
    });

export const login = async (data) => {
  return await axios.post(`${apiURL}/login`, data);
};

export const cekLogin = async () => {
  return await axios.get(`${apiURL}/me`);
};

// USERS
export const getUsers = async () => {
  return await axios.get(`${apiURL}/users`);
};
export const getUser = async (id) => {
  return await axios.get(`${apiURL}/users/${id}`);
};
export const addUser = async (data) => {
  return await axios.post(`${apiURL}/users`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateUser = async (data, uuid) => {
  return await axios.patch(`${apiURL}/users/${uuid}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteUser = async (uuid) => {
  return await axios.delete(`${apiURL}/users/${uuid}`, getHeaders());
};

// BANNERS
export const addBanner = async (data) => {
  return await axios.post(`${apiURL}/banner`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateBanner = async (data, id) => {
  return await axios.patch(`${apiURL}/banner/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteBanner = async (id) => {
  return await axios.delete(`${apiURL}/banner/${id}`, getHeaders());
};

export const getBelts = async () => {
  return await axios.get(`${apiURL}/sabuks`);
};

export const getBelt = async (code) => {
  return await axios.get(`${apiURL}/sabuks/${code}`);
};

// EVENTS
export const getEvents = async () => {
  return await axios.get(`${apiURL}/events`);
};
export const getEvent = async (code) => {
  return await axios.get(`${apiURL}/events/${code}`);
};
export const addEvent = async (data) => {
  return await axios.post(`${apiURL}/event`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateEvent = async (data, id) => {
  return await axios.patch(`${apiURL}/event/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteEvent = async (id) => {
  return await axios.delete(`${apiURL}/event/${id}`, getHeaders());
};

export const getOrgLevels = async () => {
  return await axios.get(`${apiURL}/levelorgs`);
};

export const getOrgLevel = async (id) => {
  return await axios.get(`${apiURL}/levelorgs/${id}`);
};

export const getOrgs = async () => {
  return await axios.get(`${apiURL}/organisations`);
};

export const getOrg = async (id) => {
  return await axios.get(`${apiURL}/organisations/${id}`);
};

export const getSuggestions = async () => {
  return await axios.get(`${apiURL}/sarans`);
};

export const getSuggestion = async (id) => {
  return await axios.get(`${apiURL}/sarans/${id}`);
};

export const postSuggestion = async (data) => {
  return await axios.post(`${apiURL}/saran`, data);
};

// CONTACT
export const getContacts = async () => {
  return await axios.get(`${apiURL}/contacts`);
};
export const getContact = async (id) => {
  return await axios.get(`${apiURL}/contacts/${id}`);
};
export const addContact = async (data) => {
  return await axios.post(`${apiURL}/contact`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateContact = async (data, id) => {
  return await axios.patch(`${apiURL}/contact/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// SAMBUTAN
export const updateSambutan = async (data) => {
  return await axios.patch(`${apiURL}/sambutan`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// NEWS
export const deleteNews = async (id) => {
  return await axios.delete(`${apiURL}/berita/${id}`, getHeaders());
};
export const addNews = async (data) => {
  return await axios.post(`${apiURL}/berita`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateNews = async (data, id) => {
  return await axios.patch(`${apiURL}/berita/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// MAKNA
export const updateMakna = async (data) => {
  return await axios.patch(`${apiURL}/maknalambang`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Visi Misi Mars
export const updateVisi = async (data) => {
  return await axios.patch(`${apiURL}/visi`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateMisi = async (data) => {
  return await axios.patch(`${apiURL}/misi`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateMars = async (data) => {
  return await axios.patch(`${apiURL}/mars`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Pengurus
export const deletePengurus = async (id) => {
  return await axios.delete(`${apiURL}/pengurus/${id}`, getHeaders());
};
export const addPengurus = async (data) => {
  return await axios.post(`${apiURL}/pengurus`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updatePengurus = async (data, id) => {
  return await axios.patch(`${apiURL}/pengurus/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Anggota
export const deleteAnggota = async (id) => {
  return await axios.delete(`${apiURL}/anggota/${id}`, getHeaders());
};
export const addAnggota = async (data) => {
  return await axios.post(`${apiURL}/anggota`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateAnggota = async (data, id) => {
  return await axios.patch(`${apiURL}/anggota/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// DOJO
export const deleteDojo = async (id) => {
  return await axios.delete(`${apiURL}/dojo/${id}`, getHeaders());
};
export const addDojo = async (data) => {
  return await axios.post(`${apiURL}/dojo`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateDojo = async (data, id) => {
  return await axios.patch(`${apiURL}/dojo/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Merchandise
export const deleteMerch = async (id) => {
  return await axios.delete(`${apiURL}/merchandise/${id}`, getHeaders());
};
export const addMerch = async (data) => {
  return await axios.post(`${apiURL}/merchandise`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateMerch = async (data, id) => {
  return await axios.patch(`${apiURL}/merchandise/${id}`, data, {
    headers: {
      'x-access-token': getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Org & Saran
export const deleteSaran = async (id) => {
  return await axios.delete(`${apiURL}/saran/${id}`, getHeaders());
};

export const deleteOrg = async (code) => {
  return await axios.delete(`${apiURL}/organisation/${code}`, getHeaders());
};
export const addOrg = async (data) => {
  return await axios.post(`${apiURL}/organisation`, data, getHeaders());
};
export const updateOrg = async (data, id) => {
  return await axios.patch(`${apiURL}/organisation/${id}`, data, getHeaders());
};

// DATA
export const deleteLevelOrg = async (code) => {
  return await axios.delete(`${apiURL}/levelorg/${code}`, getHeaders());
};
export const addLevelOrg = async (data) => {
  return await axios.post(`${apiURL}/levelorg`, data, getHeaders());
};
export const updateLevelOrg = async (data, code) => {
  return await axios.patch(`${apiURL}/levelorg/${code}`, data, getHeaders());
};

export const deleteSabuk = async (code) => {
  return await axios.delete(`${apiURL}/sabuks/${code}`, getHeaders());
};
export const addSabuk = async (data) => {
  return await axios.post(`${apiURL}/sabuks`, data, getHeaders());
};
export const updateSabuk = async (data, code) => {
  return await axios.patch(`${apiURL}/sabuks/${code}`, data, getHeaders());
};

export const deleteJabatan = async (code) => {
  return await axios.delete(`${apiURL}/jabatan/${code}`, getHeaders());
};
export const addJabatan = async (data) => {
  return await axios.post(`${apiURL}/jabatan`, data, getHeaders());
};
export const updateJabatan = async (data, code) => {
  return await axios.patch(`${apiURL}/jabatan/${code}`, data, getHeaders());
};

export const deleteKatMerch = async (id) => {
  return await axios.delete(`${apiURL}/kategorimrc/${id}`, getHeaders());
};
export const addKatMerch = async (data) => {
  return await axios.post(`${apiURL}/kategorimrc`, data, getHeaders());
};
export const updateKatMerch = async (data, id) => {
  return await axios.patch(`${apiURL}/kategorimrc/${id}`, data, getHeaders());
};

// PICs
export const addPic = async (data) => {
  return await axios.post(`${apiURL}/dojopic`, data, getHeaders());
};
export const deletePic = async (id) => {
  return await axios.delete(`${apiURL}/dojopic/${id}`, getHeaders());
};
