import { useEffect, useState } from 'react';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';
import useSWR from 'swr';
import { fetcherAuth, updateSambutan } from '../../../services';
import Info from '../../../components/Info';
import { excerpt } from '../../../helper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputImage from '../../components/InputImage';

const columns = ['No', 'Title', 'Description', 'Image'];

function AddEditSambutan({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    tittle: '',
    des: '',
    url_img: '',
  });
  const [des, setDes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
    setDes(editData && editData.des && editData.des);
  }, [editData]);

  // const clearForm = () => {
  //   setFormData({
  //     tittle: '',
  //     des: '',
  //     url_img: '',
  //   });
  //   setDes('');
  //   setIsShow(false);
  // };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('tittle', formData.tittle);
    formDataObj.append('des', des);
    typeof formData.url_img !== 'string' && formDataObj.append('img', formData.url_img);
    try {
      const response = await updateSambutan(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      setIsShow(false);
    }
  };

  const isFormEmpty = () => {
    let result = false;
    for (const [_, value] of Object.entries(formData)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  const onChangeDesc = (value) => {
    setDes(value);
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => setIsShow(false)}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Sambutan</h1>
            <button type="button" className="btn-close" onClick={() => setIsShow(false)}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label className="form-label">Photo</label>
                <InputImage
                  imgSrc={formData.url_img ? formData.url_img : ''}
                  name="url_img"
                  changeInputImage={(e) => setFormData({ ...formData, url_img: e.target.files[0] })}
                />
                {/* <input
                  className="form-control"
                  type="file"
                  id="img"
                  name="img"
                  onChange={(e) =>
                    setFormData({ ...formData, img: e.target.files[0] })
                  }
                ></input> */}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="des" className="form-label">
                  Sambutan
                </label>
                <ReactQuill theme="snow" value={des ? des : ''} onChange={onChangeDesc} />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="tittle" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  id="tittle"
                  className="form-control"
                  value={formData.tittle ? formData.tittle : ''}
                  onChange={(e) => setFormData({ ...formData, tittle: e.target.value })}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => setIsShow(false)}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Greeting() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('sambutan', fetcherAuth);

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  function dataRow() {
    return (
      data &&
      data.result.map((d, i) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{d.tittle}</td>
          <td>{excerpt(d.des, 15)}</td>
          <td>
            <img width={140} src={d.url_img} alt="logo_image" />
          </td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                setIsShow(true);
                setIsEdit(true);
                setEditData(d);
              }}
            >
              Edit
            </button>
          </td>
        </tr>
      ))
    );
  }

  return (
    <div>
      <h2>Sambutan</h2>
      <hr />
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table columns={columns} children={dataRow()} />
      {/* <Pagination /> */}
      <AddEditSambutan
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
