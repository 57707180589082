import { Link, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('userData');
    navigate('/login');
  };
  return (
    <header className="flex sticky-top">
      <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/cms">
            Budokai CMS
          </Link>
          <button onClick={logout} className="btn btn-warning">
            Logout
          </button>
        </div>
      </nav>
    </header>
  );
}
