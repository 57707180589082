import { useEffect, useState } from 'react';
import Banner from './components/Banner';
import { getEvents } from '../services';
import { months } from '../helper';
import useSWR from 'swr';
import { fetcher } from '../services';

export default function Activities() {
  const [activityData, setActivityData] = useState([[], [], [], [], [], [], [], [], [], [], [], []]);
  const { data } = useSWR('banners?menu=kegiatan', fetcher);

  useEffect(() => {
    getEvents().then((response) => {
      let thisYearEvents = response.data.result.filter((r) => {
        return new Date(r.date).getFullYear() === new Date().getFullYear();
      });

      thisYearEvents.forEach((e) => {
        let month = new Date(e.date).getMonth();
        let tempAD = [...activityData];
        if (!activityData[month].find((d) => d.id === e.id)) tempAD[month].push(e);
        setActivityData([...tempAD]);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="activities">
      {data && <Banner images={data.result} />}
      <section>
        <h2>Daftar Kegiatan Budokai</h2>
        <div className="container">
          <div className="left">
            {activityData.map(
              (activity, i) =>
                i <= 5 && (
                  <div key={i} className="month">
                    <h4>{months[i]}</h4>
                    <div>
                      {activity.length ? (
                        activity.map((a) => (
                          <div key={a.id}>
                            <p>{a.date}</p>
                            {a.link ? (
                              <a target="_blank" rel="noopener noreferrer" href={a.link}>
                                {a.title}
                              </a>
                            ) : (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a>{a.title}</a>
                            )}
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>Tidak ada kegiatan</p>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="right">
            {activityData.map(
              (activity, i) =>
                i > 5 && (
                  <div key={i} className="month">
                    <h4>{months[i]}</h4>
                    <div>
                      {activity.length ? (
                        activity.map((a) => (
                          <div key={a.id}>
                            <p>{a.date}</p>
                            {a.link !== '' ? <a href={a.link}>{a.title}</a> : <span>{a.title}</span>}
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>Tidak ada kegiatan</p>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
