import Banner from './components/Banner';
import Pagination from './components/Pagination';
import { fetcher } from '../services';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { abjads, scrollToTop } from '../helper';
import { MemberDetail } from './Dojo';

export default function Member() {
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [pengcab, setPengcab] = useState('');
  const [memberId, setMemberId] = useState(0);
  const [dojo, setDojo] = useState('');
  const [searchName, setSearchName] = useState('');
  const [abjadName, setAbjadName] = useState('');
  const [abjad, setAbjad] = useState(searchName ? false : true);
  const [url, setUrl] = useState(`anggotas?limit=${limit}&page=${page}`);
  const { data } = useSWR(url, fetcher);
  const { data: dataPengcab } = useSWR(`organisations`, fetcher);
  const { data: dataDojo } = useSWR(
    `dojos?limit=999&search_pengcab=${pengcab}`,
    fetcher
  );
  const { data: dataBanner } = useSWR('banners?menu=anggota', fetcher);

  const filterData = (e) => {
    e.preventDefault();
    setPage(0);
    setUrl(
      `anggotas?limit=${limit}&page=${0}&search_name=${searchName}&search_pengcab=${pengcab}&search_dojo=${dojo}&search_inisial=${abjadName}`
    );
  };

  const searchByAbjad = (data) => {
    setAbjadName(data);
    setUrl(
      `anggotas?limit=${limit}&page=${page}&search_pengcab=${pengcab}&search_dojo=${dojo}&search_inisial=${data}`
    );
  };

  const handleChangePage = (e) => {
    setPage(e);
    setUrl(
      `anggotas?limit=${limit}&page=${e}&search_name=${searchName}&search_pengcab=${pengcab}&search_dojo=${dojo}&search_inisial=${abjadName}`
    );
    scrollToTop();
  };

  const handleChangeLimit = (e) => {
    setLimit(e);
    setPage(0);
    setUrl(
      `anggotas?limit=${e}&page=${0}&search_name=${searchName}&search_pengcab=${pengcab}&search_dojo=${dojo}&search_inisial=${abjadName}`
    );
    scrollToTop();
  };

  useEffect(() => {
    if (searchName) {
      setAbjad(false);
    } else {
      setAbjad(true);
    }
  }, [searchName]);

  return (
    <div className="member">
      {dataBanner && <Banner images={dataBanner.result} />}
      <section>
        <h2>Anggota Budokai</h2>
        <div className="container">
          <form className="row" onSubmit={filterData}>
            <div className="col-md-3 mb-1">
              <select
                className="form-select"
                onChange={(e) => setPengcab(e.target.value)}
                value={pengcab}
              >
                <option value="">All</option>
                {dataPengcab &&
                  dataPengcab.result.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 mb-1">
              <select
                className="form-select"
                onChange={(e) => setDojo(e.target.value)}
                value={dojo}
              >
                <option value="">All</option>
                {dataDojo &&
                  dataDojo.result.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 mb-1">
              <input
                disabled={abjadName}
                type="text"
                className="form-control search"
                placeholder="Cari Nama Anggota"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <button type="submit" className="btn btn-danger btn-sm mb-3">
                Cari
              </button>
            </div>
          </form>
          <div className="filter">
            {abjad && (
              <>
                <span
                  className={abjadName === '' ? 'active' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => searchByAbjad('')}
                >
                  Semua
                </span>
                <div className="alphabet">
                  {abjads.map((a) => (
                    <span
                      key={a.name}
                      className={abjadName === a.name ? 'active' : ''}
                      style={{ cursor: 'pointer' }}
                      onClick={() => searchByAbjad(a.name)}
                    >
                      {a.name}
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="member-list">
            {data && data.result.length ? (
              data.result.map((d) => (
                <div
                  key={d.id}
                  className="member-list-item"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setMemberId(d.id)}
                >
                  {/* <img src={d.url_foto} alt="member" /> */}
                  <img
                    src={
                      d.url_foto
                        ? d.url_foto
                        : 'https://apidev.budokaijakarta.org/anggota/photo_6258075209689052837_m.jpg'
                    }
                    alt="member"
                  />
                  <div>
                    <h5>{d.name}</h5>
                    <p>
                      Sabuk {d.sabuk && d.sabuk.des} -{' '}
                      {d.sabuk && d.sabuk.grade}
                    </p>
                    <p>{d.dojo.name}</p>
                    <p>{d.dojo.organisation.name.replace('Pengurus', '')}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>Data Kosong</p>
            )}
          </div>
          {data && (
            <Pagination
              data={data}
              onChangeLimit={(e) => handleChangeLimit(e)}
              onChangePage={(e) => handleChangePage(e)}
              limit={limit}
              page={page}
            />
          )}
          {memberId !== 0 && (
            <div className="pic" onClick={() => setMemberId(0)}>
              <MemberDetail id={memberId} />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
