import { useEffect, useState } from 'react';
import { addUser, updateUser } from '../../../services';

export default function AddEditUser({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confPassword: '',
    role: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData({ ...editData, password: '', confPassword: '' });
  }, [editData]);

  const submit = async () => {
    setIsSubmitting(true);
    try {
      const response = isEdit ? await updateUser(formData, editData.uuid) : await addUser(formData);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;
    let obj = { ...formData, password: 'x', confPassword: 'x' };
    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  const clearForm = () => {
    setFormData({
      name: '',
      email: '',
      password: '',
      confPassword: '',
      role: '',
    });
    setIsShow(false);
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className={isShow ? 'modal-dialog modal-dialog-x' : 'modal-dialog modal-dialog-x hidden'}>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} User</h1>
            <button type="button" className="btn-close" onClick={clearForm}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={formData.name ? formData.name : ''}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  value={formData.email ? formData.email : ''}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={formData.password ? formData.password : ''}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="confPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confPassword"
                  className="form-control"
                  value={formData.confPassword ? formData.confPassword : ''}
                  onChange={(e) => setFormData({ ...formData, confPassword: e.target.value })}
                />
                {formData.password !== formData.confPassword && (
                  <div className="alert alert-danger mt-2">Password tidak sama</div>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="role">Role</label>
                <select
                  id="role"
                  className="form-select"
                  value={formData.role ? formData.role : ''}
                  onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                >
                  <option value=""></option>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary me-2" onClick={clearForm}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
