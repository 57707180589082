import { useEffect, useState } from 'react';
import { addPengurus, deletePengurus, fetcher, fetcherAuth, updatePengurus } from '../../../services';
import useSWR from 'swr';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import Pagination from '../../components/Pagnation';

function AddEditOrg({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  // const { data: dataAnggota } = useSWR('anggotas?limit=10', fetcherAuth);
  const [dataAnggota, setDataAnggota] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [dataJabatan, setDataJabatan] = useState([]);
  const [dataOrg, setDataOrg] = useState([]);

  const [formData, setFormData] = useState({
    level: '',
    anggotum: { id: '' },
    organisation: { id: '' },
    Jabatan: { id: '' },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    editData && setFormData(editData);
  }, [editData]);

  const getAnggota = (limit = 100, name = '') => {
    fetcher(`anggotas?limit=${limit}&search_name=${name}`).then((res) => {
      setDataAnggota(res.result);
    });
  };

  const getJabatan = () => {
    fetcher(`jabatans`).then((res) => {
      setDataJabatan(res.result);
    });
  };

  const getOrg = () => {
    fetcher(`organisations`).then((res) => {
      setDataOrg(res.result);
    });
  };

  useEffect(() => {
    getJabatan();
    getOrg();
  }, []);

  const clearForm = () => {
    setFormData({
      level: '',
      anggotum: { id: '' },
      organisation: { id: '' },
      Jabatan: { id: '' },
    });
    setIsShow(false);
    setSearchName('');
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('level', formData.level);
    formDataObj.append('anggotaid', formData.anggotum.id);
    formDataObj.append('organisationid', formData.organisation.id);
    formDataObj.append('jabatanid', formData.Jabatan.id);

    try {
      const response = isEdit ? await updatePengurus(formDataObj, editData.id) : await addPengurus(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;
    let obj = {
      level: formData.level,
      anggotaid: formData.anggotum.id,
      orgid: formData.organisation.id,
      jabatanid: formData.Jabatan.id,
    };
    for (const [_, value] of Object.entries(obj)) {
      if (!value) {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={() => setIsShow(false)}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Informasi</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label className="form-label">Level</label>
                <select
                  className="form-select"
                  value={formData.level ? formData.level : ''}
                  onChange={(e) => setFormData({ ...formData, level: e.target.value })}
                >
                  <option value="">Pilih Level</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="body" className="form-label">
                  Anggota
                </label>
                <div className="d-flex gap-2 align-item-center mb-2">
                  <input type="text" className="form-control" onChange={(e) => setSearchName(e.target.value)} />
                  <button
                    disabled={!searchName}
                    className="btn btn-primary"
                    type="button"
                    onClick={() => getAnggota(100, searchName)}
                  >
                    Cari
                  </button>
                </div>
                <select
                  disabled={!dataAnggota.length}
                  className="form-select"
                  value={formData.anggotum.id ? formData.anggotum.id : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      anggotum: { id: e.target.value },
                    })
                  }
                >
                  <option value="">Pilih Anggota</option>
                  {dataAnggota.length &&
                    dataAnggota.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="body" className="form-label">
                  Jabatan
                </label>
                <select
                  className="form-select"
                  value={formData.Jabatan.id ? formData.Jabatan.id : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Jabatan: { id: e.target.value },
                    })
                  }
                >
                  <option value="">Pilih Jabatan</option>
                  {dataJabatan.length &&
                    dataJabatan.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.des}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="body" className="form-label">
                  Organisasi
                </label>
                <select
                  className="form-select"
                  value={formData.organisation.id ? formData.organisation.id : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      organisation: { id: e.target.value },
                    })
                  }
                >
                  <option value="">Pilih Organisasi</option>
                  {dataOrg.length &&
                    dataOrg.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = ['No', 'Level', 'Name', 'Jabatan', 'Organisasi', 'Action'];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isCode = false }) {
  return (
    result &&
    result.map((d) => (
      <tr key={isCode ? d.code : d.id}>
        <td>{d.no}</td>
        <td>{d.level}</td>
        <td>{d.anggotum.name}</td>
        <td>{d.Jabatan.des}</td>
        <td>{d.organisation.name}</td>
        <td>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              onIsShow(true);
              onIsEdit(true);
              onEditData(d);
            }}
          >
            Edit
          </button>
          &nbsp;
          <button
            disabled={isDeleting}
            onClick={() => onDeleteRow(isCode ? d.code : d.id)}
            className="btn btn-sm btn-danger"
            title="Delete"
          >
            Delete
          </button>
        </td>
      </tr>
    ))
  );
}

export default function OrgStructure() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchData, setSearchData] = useState('');
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });
  const { data, mutate } = useSWR('pengurus', fetcherAuth);

  // Pagination
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const dataWC =
    data &&
    data.result
      .filter((d) => {
        let pattern = new RegExp(searchData, 'ig');
        return pattern.test(d.anggotum.name) || pattern.test(d.Jabatan.des);
      })
      .map((d, i) => {
        return { ...d, no: i + 1 };
      });
  const currentItems = dataWC && dataWC.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dataWC && dataWC.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % (data && data.result.length);
    setItemOffset(newOffset);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deletePengurus(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Struktur Organisasi</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Struktur Organisasi"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Name or Jabatan"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
          />
        }
      />
      <Pagination pageCount={pageCount} onPageClick={handlePageClick} />
      <AddEditOrg
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
