import { useEffect, useState } from 'react';
import { addMerch, deleteMerch, downloadMerch, fetcher, fetcherAuth, updateMerch } from '../../../services';
import useSWR from 'swr';
import Info from '../../../components/Info';
import Table from '../../components/Table';
import { excerpt } from '../../../helper';
import InputImage from '../../components/InputImage';
import Pagination from '../../components/Pagnation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function AddEditMerch({ isShow, setIsShow, isEdit, editData, onSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    desc: '',
    price: '',
    unit: '',
    contact: '',
    url_image: '',
    kategorimrc: { id: '' },
  });
  const [mainImage, setMainImage] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataKetegori, setDataKetegori] = useState([]);

  useEffect(() => {
    editData && setFormData(editData);
    if (editData && editData.url_image) {
      let images = editData.url_image.split(',');
      setMainImage(images[0] ? images[0] : '');
      setImage1(images[1] ? images[1] : '');
      setImage2(images[2] ? images[2] : '');
      setImage3(images[3] ? images[3] : '');
      setImage4(images[4] ? images[4] : '');
    }
  }, [editData]);

  const getKategori = () => {
    fetcher(`kategorimrcs`).then((res) => {
      setDataKetegori(res.result);
    });
  };

  useEffect(() => {
    getKategori();
  }, []);

  const clearForm = () => {
    setFormData({
      name: '',
      desc: '',
      price: '',
      unit: '',
      contact: '',
      url_image: '',
      kategorimrc: { id: '' },
    });
    setIsShow(false);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('desc', formData.desc);
    formDataObj.append('price', formData.price);
    formDataObj.append('unit', formData.unit);
    formDataObj.append('contact', formData.contact);
    formDataObj.append('kategorimrcid', formData.kategorimrc.id);
    typeof mainImage === 'object' && formDataObj.append('imgMain', mainImage);
    typeof image1 === 'object' && formDataObj.append('img_dtl1', image1);
    typeof image2 === 'object' && formDataObj.append('img_dtl2', image2);
    typeof image3 === 'object' && formDataObj.append('img_dtl3', image3);
    typeof image4 === 'object' && formDataObj.append('img_dtl4', image4);

    try {
      const response = isEdit ? await updateMerch(formDataObj, editData.id) : await addMerch(formDataObj);
      if (response) {
        setIsShow(false);
        onSubmit(response);
      }
    } catch (err) {
      console.log(err);
      onSubmit(err);
    } finally {
      setIsSubmitting(false);
      clearForm();
    }
  };

  const isFormEmpty = () => {
    let result = false;

    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries({
      name: formData.name,
      price: formData.price,
      kategorimrcid: formData.kategorimrc.id,
    })) {
      if (value === '') {
        result = true;
        break;
      }
    }

    return result;
  };

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="modal-overlay" onClick={clearForm}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5"> {isEdit ? 'Edit' : 'Add'} Merchandise</h1>
            <button type="button" className="btn-close" onClick={() => clearForm()}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={formData.name ? formData.name : ''}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  id="price"
                  className="form-control"
                  value={formData.price ? formData.price : ''}
                  onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="unit" className="form-label">
                  Unit
                </label>
                <input
                  type="text"
                  id="unit"
                  className="form-control"
                  value={formData.unit ? formData.unit : ''}
                  onChange={(e) => setFormData({ ...formData, unit: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="contact" className="form-label">
                  Contact
                </label>
                <input
                  type="text"
                  id="contact"
                  className="form-control"
                  value={formData.contact ? formData.contact : ''}
                  onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Kategori Merch</label>
                <select
                  className="form-select"
                  value={formData.kategorimrc.id ? formData.kategorimrc.id : ''}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      kategorimrc: { id: e.target.value },
                    })
                  }
                >
                  <option value="">Pilih Kategori Merch</option>
                  {dataKetegori.length &&
                    dataKetegori.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.des}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="desc" className="form-label">
                  Description
                </label>
                <textarea
                  id="desc"
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={formData.desc ? formData.desc : ''}
                  onChange={(e) => setFormData({ ...formData, desc: e.target.value })}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Main Image</label>
                <InputImage
                  imgSrc={mainImage ? mainImage : ''}
                  name="imgMain"
                  changeInputImage={(e) => setMainImage(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 1</label>
                <InputImage
                  imgSrc={image1 ? image1 : ''}
                  name="img_dtl1"
                  changeInputImage={(e) => setImage1(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 2</label>
                <InputImage
                  imgSrc={image2 ? image2 : ''}
                  name="img_dtl2"
                  changeInputImage={(e) => setImage2(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 3</label>
                <InputImage
                  imgSrc={image3 ? image3 : ''}
                  name="img_dtl3"
                  changeInputImage={(e) => setImage3(e.target.files[0])}
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label">Image 4</label>
                <InputImage
                  imgSrc={image4 ? image4 : ''}
                  name="img_dtl4"
                  changeInputImage={(e) => setImage4(e.target.files[0])}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => clearForm()}>
              Close
            </button>
            <button type="submit" className="btn btn-primary" disabled={isFormEmpty() || isSubmitting} onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = ['No', 'Name', 'Description', 'Price', 'Action'];

function Row({ result, isDeleting, onIsShow, onIsEdit, onEditData, onDeleteRow, isLoading, isCode = false }) {
  return isLoading ? (
    <tr>
      <td colSpan="5">Loading...</td>
    </tr>
  ) : (
    result &&
      result.map((d) => (
        <tr key={isCode ? d.code : d.id}>
          <td>{d.no}</td>
          <td>{d.name}</td>
          <td>{excerpt(d.desc, 120)}</td>
          <td>{d.price}</td>
          <td>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                onIsShow(true);
                onIsEdit(true);
                onEditData(d);
              }}
            >
              Edit
            </button>
            &nbsp;
            <button
              disabled={isDeleting}
              onClick={() => onDeleteRow(isCode ? d.code : d.id)}
              className="btn btn-sm btn-danger"
              title="Delete"
            >
              Delete
            </button>
          </td>
        </tr>
      ))
  );
}

export default function MerchandiseCMS() {
  const [isShow, setIsShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [info, setInfo] = useState({
    isShow: false,
    type: '',
    text: '',
  });

  // Pagination
  const itemsPerPage = 5;
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [url, setUrl] = useState(`merchandises?limit=${itemsPerPage}&page=${page}`);
  const { data, mutate, isLoading } = useSWR(url, fetcherAuth);
  const dataWC =
    data &&
    data.result.map((d, i) => {
      return { ...d, no: page * itemsPerPage + (i + 1) };
    });
  const currentItems = dataWC && dataWC;
  const pageCount = Math.ceil(data && data.totalRows / itemsPerPage);

  const handlePageClick = (event) => {
    setPage(event.selected);
    setUrl(`merchandises?limit=${itemsPerPage}&page=${event.selected}&search_name=${searchName}`);
  };

  const handleItemsSearch = () => {
    setPage(0);
    setUrl(`merchandises?limit=${itemsPerPage}&page=0&search_name=${searchName}`);
  };

  const deleteRow = async (id) => {
    setIsDeleting(true);
    try {
      const response = await deleteMerch(id);
      if (response) {
        mutate();
        setInfo({ isShow: true, type: 'success', text: response.data.msg });
      }
    } catch (err) {
      console.log(err);
      setInfo({ isShow: true, type: 'danger', text: err.message });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = (res) => {
    if (res.response && res.response.status >= 400) {
      setInfo({
        isShow: true,
        type: 'danger',
        text: res.response.data || res.message,
      });
      return;
    }
    setInfo({ isShow: true, type: 'success', text: res.data.msg });
    mutate();
  };

  return (
    <div>
      <h2>Merchandise</h2>
      <hr />
      <div className="d-flex align-items-start justify-content-between">
        <button
          onClick={() => {
            setIsShow(true);
            setIsEdit(false);
            setEditData(null);
          }}
          type="button"
          className="btn btn-primary mb-2"
          title="Add Merchandise"
        >
          Add
        </button>
        <div className="d-flex align-items-center gap-2">
          <input type="text" className="form-control" onChange={(e) => setSearchName(e.target.value)} />
          <button className="btn btn-warning" onClick={handleItemsSearch}>
            Search
          </button>
        </div>
      </div>
      {info.isShow && <Info type={info.type} text={info.text} onClose={() => setInfo({ ...info, isShow: false })} />}
      <Table
        columns={columns}
        children={
          <Row
            result={currentItems}
            isDeleting={isDeleting}
            onIsShow={setIsShow}
            onIsEdit={setIsEdit}
            onEditData={setEditData}
            onDeleteRow={deleteRow}
            isLoading={isLoading}
          />
        }
      />
      <div className="d-flex align-items-center justify-content-between">
        <Pagination pageCount={pageCount} onPageClick={handlePageClick} page={page} />
        <a title="Download" className="btn btn-danger" href={downloadMerch} download={downloadMerch}>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </div>
      <AddEditMerch
        isShow={isShow}
        setIsShow={(e) => setIsShow(e)}
        isEdit={isEdit}
        editData={editData}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
