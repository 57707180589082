import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import WebLayout from './WEB/WebLayout';
import Home from './WEB/Home';
import About from './WEB/About';
import Activities from './WEB/Activities';

import CMSLayout from './CMS/CMSLayout';
import Login from './CMS/Login';
import Dashboard from './CMS/Dashboard';

import News from './WEB/News';
import NewsList from './WEB/NewsList';
import Dojo from './WEB/Dojo';
import Member from './WEB/Member';
import Contact from './WEB/Contact';
import Greeting from './CMS/pages/home/Greeting';
import AboutCMS from './CMS/pages/about';
import OrgStructure from './CMS/pages/about/OrgStructure';
import Mars from './CMS/pages/about/Mars';
import Events from './CMS/Events';
import NewsCMS from './CMS/pages/news';
import Dojos from './CMS/pages/dojos';
import MembersCMS from './CMS/pages/members';
import MerchandiseCMS from './CMS/pages/merchandise';
import ContactCMS from './CMS/pages/contact';
import Merchandise from './WEB/Merchandise';

import User from './CMS/pages/user';
import Banner from './CMS/pages/banner';
import NotFound from './components/404';
import Visi from './CMS/pages/about/Visi';
import Misi from './CMS/pages/about/Misi';
import Makna from './CMS/pages/about/Makna';
import OrgNSaran from './CMS/pages/OrgNSaran';
import Saran from './CMS/pages/OrgNSaran/Saran';
import Org from './CMS/pages/OrgNSaran/Org';

import { isAdmin } from './services';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DataCMS from './CMS/pages/data';
import LevelOrg from './CMS/pages/data/LevelOrg';
import Sabuk from './CMS/pages/data/Sabuk';
import Jabatan from './CMS/pages/data/Jabatan';
import MerchCategory from './CMS/pages/data/MerchCategory';

library.add(faChevronDown);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WebLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="activity" element={<Activities />} />
            <Route path="news/:slug" element={<News />} />
            <Route path="news" element={<NewsList />} />
            <Route path="dojo" element={<Dojo />} />
            <Route path="member" element={<Member />} />
            <Route path="merchandise" element={<Merchandise />} />
            <Route path="contact" element={<Contact />} />
          </Route>

          <Route path="/cms" element={<CMSLayout />}>
            <Route index element={<Dashboard />} />
            {isAdmin() && <Route path="user" element={<User />} />}
            <Route path="banner" element={<Banner />} />
            <Route path="contact-footer" element={<ContactCMS />} />
            <Route path="greeting" element={<Greeting />} />
            <Route path="events" element={<Events />} />
            <Route path="news" element={<NewsCMS />} />

            <Route path="about" element={<AboutCMS />}>
              <Route index element={<Makna />} />
              <Route path="org-structure" element={<OrgStructure />} />
              <Route path="visi" element={<Visi />} />
              <Route path="misi" element={<Misi />} />
              <Route path="mars" element={<Mars />} />
            </Route>

            <Route path="members" element={<MembersCMS />} />
            <Route path="dojos" element={<Dojos />} />
            <Route path="merchandise" element={<MerchandiseCMS />} />

            <Route path="contacts" element={<OrgNSaran />}>
              <Route index element={<Saran />} />
              <Route path="org" element={<Org />} />
            </Route>

            <Route path="data" element={<DataCMS />}>
              <Route index element={<LevelOrg />} />
              <Route path="sabuk" element={<Sabuk />} />
              <Route path="jabatan" element={<Jabatan />} />
              <Route path="merch-category" element={<MerchCategory />} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
