import Banner from './components/Banner';
import { Link } from 'react-router-dom';
import Pagination from './components/Pagination';
import useSWR from 'swr';
import { fetcher } from '../services';
import { excerpt, formatedDate, removeTags } from '../helper';
import { useState } from 'react';

export default function NewsList() {
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState('DESC');
  const { data } = useSWR(
    `beritas?limit=${limit}&page=${page}&order_by=${sort}`,
    fetcher
  );
  const { data: dataBanner } = useSWR('banners?menu=informasi', fetcher);

  const onChangeSorting = (e) => {
    setSort(e.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="news-list">
      {dataBanner && <Banner images={dataBanner.result} />}
      <section>
        <h2>Informasi</h2>
        <select
          name="order"
          className="form-select order"
          onChange={onChangeSorting}
          value={sort}
        >
          <option value="DESC">Terbaru</option>
          <option value="ASC">Terlama</option>
        </select>
        <div className="container">
          {data &&
            data.result.map((n) => (
              <div key={n.id} className="news">
                <img src={n.url_image2} alt={n.tittle} />
                <div>
                  <p className="date">{formatedDate(n.updatedAt)}</p>
                  <h3>{n.tittle}</h3>
                  <p className="content">{removeTags(excerpt(n.body))}</p>
                  <Link to={'/news/' + n.slugs} onClick={() => scrollToTop()}>
                    Baca Lebih Lengkap
                  </Link>
                </div>
              </div>
            ))}

          {data && (
            <Pagination
              data={data}
              onChangeLimit={(e) => setLimit(e)}
              onChangePage={(e) => setPage(e)}
              limit={limit}
              page={page}
            />
          )}
        </div>
      </section>
    </div>
  );
}

// NOTES
// ganti tittle jadi title
// kurang slug
// fix width gambar
